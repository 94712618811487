import HorizontalTimeline from "@/components/shared/HorizontalTimeline/HorizontalTimeline";
import { usePurchaseTimeline } from "@/hooks/usePurchaseProducts";
import styles from "./styles.module.scss";

type TPurchaseTimeline = {
  id: string;
};
export const PurchaseTimeline = ({ id }: TPurchaseTimeline) => {
  const timeline = usePurchaseTimeline(id);

  if (!timeline) return null;

  if (!timeline?.length) return null;

  const timeLineMapped = timeline.map((modification: any) => ({
    ...modification,
    date: modification?.createdAt,
  }));

  return (
    <>
      <div className={styles.timeline}>
        <HorizontalTimeline events={timeLineMapped} />
      </div>
    </>
  );
};
