import { isUSA } from "@/constants/core";
import { DATE_HOUR_SHORT, USA_DATE_HOUR_SHORT } from "@/constants/date";
import { paymentStatus, purchaseStatus } from "@/constants/purchases";
import { QUOTED, TPurchase } from "@/http";
import { formatCurrency } from "@/utils/currency";
import format from "date-fns/format";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { exists } from "i18next";
import { SellerResume } from "@/components/shared/Sellers/SellerResume/SellerResume";
import { get } from "lodash-es";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { CheckboxButton } from "@/components/shared/Input/CheckboxButton";
import { usePurchases } from "@/store/purchases";
import clsx from "clsx";

type TPurchaseListRow = {
  purchase: TPurchase;
};
export const PurchaseListRow = ({ purchase }: TPurchaseListRow) => {
  const [globalT] = useTranslation("global");
  const [t] = useTranslation("purchases");
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const isProcurementFilter = search.get("isProcurement") === "true";
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const currentFilter = statusFilters["status"];
  const { role, isCommercial } = useAuth();
  const isQuotes = currentFilter === QUOTED;
  const isSale = !isProcurementFilter && !isQuotes;
  const parentRoute = isQuotes
    ? "quotes"
    : isProcurementFilter
    ? "acquisitions"
    : "purchases";
  const { selectedPurchases, setSelectedPurchases } = usePurchases();

  const onClick = () => navigate(`/${parentRoute}/${purchase.id}/show`);

  return (
    <tr className={styles.purchaseRow}>
      {isSale && (
        <td>
          <CheckboxButton
            checked={Boolean(selectedPurchases[purchase.id])}
            onChange={() => {
              setSelectedPurchases(purchase);
            }}
          />
        </td>
      )}
      <td onClick={onClick}>
        <div className="flexColumn lh1">
          <span className="textLine">
            {format(
              new Date(purchase.createdAt),
              isUSA ? USA_DATE_HOUR_SHORT : DATE_HOUR_SHORT
            )}
          </span>
          {!isProcurementFilter && (
            <span className={clsx("textLine bold", styles.originFont)}>
              {purchase?.origin?.name
                ? globalT(
                    `PAYMENT_METHOD_CODE.${purchase?.origin?.name?.toUpperCase()}`
                  )
                : "-"}
            </span>
          )}
        </div>
      </td>
      {isQuotes &&
        hasPermission(isCommercial, role, [
          "action.quotes.show-commercial-creation",
        ]) && <td onClick={onClick}>{purchase?.createdByName || "-"}</td>}
      <td onClick={onClick}>{purchase.purchaseNumber}</td>
      <td onClick={onClick}>
        <div className={isSale ? "flexColumn center leftAlign lh1" : ""}>
          {get(
            purchase,
            "paymentProvider",
            get(purchase, "payments[0].paymentProvider")
          ) && (
            <span className="bold mdFont">
              {exists(
                `global:PAYMENT_METHOD.PROVIDERS.${get(
                  purchase,
                  "paymentProvider",
                  get(purchase, "payments[0].paymentProvider")
                )}`
              )
                ? globalT(
                    `PAYMENT_METHOD.PROVIDERS.${get(
                      purchase,
                      "paymentProvider",
                      get(purchase, "payments[0].paymentProvider")
                    )}`
                  )
                : purchase.paymentProvider}
            </span>
          )}
        </div>
      </td>
      <td onClick={onClick}>{formatCurrency(purchase.total)}</td>
      {!isProcurementFilter && (
        <>
          <td>
            <div className="flexGap lh1 leftAlign">
              <SellerResume seller={purchase?.client} isSlim />
            </div>
          </td>
          <td>
            <div className="flexGap centerAlign">
              <span className="lh1">{purchase?.commercialName ?? "-"}</span>
            </div>
          </td>
        </>
      )}

      {currentFilter != QUOTED && (
        <>
          <td onClick={onClick}>
            <div className="flexColumn center leftAlign lh1 textLine">
              <p>
                <span
                  className={
                    ["PENDING"].includes(purchase.status)
                      ? "redText"
                      : ["DONE"].includes(purchase.status)
                      ? "greenText"
                      : ""
                  }
                >
                  {purchaseStatus.find(s => s.code === purchase.status)?.name}
                </span>
              </p>
              <p className="mdFont">
                <span className="bold">
                  {t("PURCHASE_DELIVERY_STATUS")}
                  {": "}
                </span>
                {purchase?.providerDeliveryStatusName}
              </p>
              <p className="mdFont">
                <span className="bold">
                  {t("PURCHASE_PAYMENT_STATUS")}
                  {": "}
                </span>
                {
                  paymentStatus.find(s => s.code === purchase.paymentStatus)
                    ?.name
                }
              </p>
              {isSale && (
                <p className="flexGap gapSm mdFont bold">
                  {purchase.hasInvoice ? (
                    <span className="greenText">
                      <BsFillCheckCircleFill />
                    </span>
                  ) : (
                    <span className="redText">
                      <BsFillXCircleFill />
                    </span>
                  )}
                  {t("INVOICED")}
                </p>
              )}
            </div>
          </td>
        </>
      )}
    </tr>
  );
};
