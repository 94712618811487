import { Checkbox } from "@/components/shared/Input/Checkbox";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { useBoxes } from "@/store/boxes";
import { createSupplierBox, updateSupplierBox } from "@/http";
import { TSupplierBox } from "@/http/types/supplier-box";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericInput } from "@/components/shared/Input/NumericInput";
import { isUSA } from "@/constants/core";
import { MdOutlineEdit } from "react-icons/md";

type TCreateBox = {
  box?: TSupplierBox;
  simpleButton?: boolean;
  onDone?: (box: TSupplierBox) => void;
};
export const CreateBox = ({
  box,
  onDone,
  simpleButton = false,
}: TCreateBox) => {
  const defaultValues = box?.id ? box : {};
  const methods = useForm<TSupplierBox>({ defaultValues });
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getBoxes } = useBoxes();
  const { user } = useAuth();
  const [t] = useTranslation("global");

  const handleSubmit = async (values: TSupplierBox) => {
    try {
      const { name, length, width, height, isMain } = values;
      let newBox;

      if (box?.id) {
        newBox = await updateSupplierBox(user.id, box.id, {
          name,
          length,
          width,
          height,
          isMain,
        });
      } else {
        newBox = await createSupplierBox(user.id, {
          ...values,
          supplierId: user.id,
        });
      }

      showToastSuccess(t(box?.id ? "UPDATED_BOX" : "CREATED_BOX"));
      getBoxes(user.id);
      setIsLoading(false);
      methods.reset(defaultValues);
      onDone?.(newBox);
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      showToastError(error);
    }
  };

  return (
    <>
      {box?.id ? (
        <ToolTip title={t("EDIT_BOX")} position="Left">
          <span
            className="circleButton pointer"
            onClick={() => setIsOpen(true)}
          >
            <MdOutlineEdit />
          </span>
        </ToolTip>
      ) : (
        <div className="rigthAlign">
          <button
            onClick={() => {
              methods.reset({ ...defaultValues });
              setIsOpen(true);
            }}
            className={simpleButton ? "bold textGreen" : "primary lg"}
          >
            + {t("CREATE_BOX")}
          </button>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          methods.reset({ ...defaultValues });
          setIsOpen(false);
        }}
        size="sm"
        title={box?.id ? `${t("EDIT_BOX")} ${box.name}` : t("CREATE_BOX")}
        footer={
          <>
            <button
              onClick={e => {
                e.preventDefault();
                methods.reset(defaultValues);
                setIsOpen(false);
              }}
              disabled={isLoading}
            >
              {t("CLOSE")}
            </button>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              {t(box?.id ? "SAVE" : "ADD")}
            </button>
          </>
        }
      >
        <FormProvider {...methods}>
          <InputV2
            name="name"
            label={t("NAME")}
            type="text"
            required
            validate={(value: string) => value.length > 2}
            validMessage={t("AT_LEAST_THREE")}
          />
          <NumericInput
            name="length"
            label={
              <span>
                {`${t("LENGTH")} (CM) `}
                <span className="smFont">Max. 40 {isUSA ? "in" : "cm"}</span>
              </span>
            }
            required
            max={40}
            min={1}
          />
          <NumericInput
            name="width"
            label={
              <span>
                {`${t("WIDTH")} (CM) `}
                <span className="smFont">Max. 35 {isUSA ? "in" : "cm"}</span>
              </span>
            }
            required
            max={35}
            min={1}
          />
          <NumericInput
            name="height"
            label={
              <span>
                {`${t("HEIGHT")} (CM) `}
                <span className="smFont">Max. 35 {isUSA ? "in" : "cm"}</span>
              </span>
            }
            required
            max={35}
            min={1}
          />
          <div className="spacer" />
          <Checkbox name="isMain" label={t("MAIN_BOX")} />
          <div className="spacer" />
        </FormProvider>
      </Modal>
    </>
  );
};
