import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { BiCalendarEvent } from "react-icons/bi";
import { format, parseISO } from "date-fns";
import {
  DATE_MONT_YEAR_SLASH,
  USA_DATE_MONT_YEAR_SLASH,
} from "@/constants/date";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import styles from "../IdFilter/styles.module.scss";
import { isUSA } from "@/constants/core";
import { useSubPurchases } from "@/store/subpurchases";
import { useSearchParams } from "react-router-dom";

const RangeDatePicker = () => {
  const [search, setSearch] = useSearchParams();
  const startDate = search.get("startDate")
    ? parseISO(search.get("startDate")!)
    : null;
  const endDate = search.get("endDate")
    ? parseISO(search.get("endDate")!)
    : null;
  const [t] = useTranslation("orders-page");
  const { clearSelectedSubPurchases } = useSubPurchases();

  const onChange = (dates: any[]) => {
    const [start, end] = dates;
    const newSearch = new URLSearchParams(search);
    if (start) {
      newSearch.set("startDate", start.toISOString().split("T")[0]);
    } else {
      newSearch.set("startDate", "");
    }

    if (end) {
      newSearch.set("endDate", end.toISOString().split("T")[0]);
    } else {
      newSearch.set("endDate", "");
    }

    newSearch.set("page", "0");

    setSearch(newSearch);
    clearSelectedSubPurchases();
  };

  return (
    <div className={styles.dateInput}>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="dd/MM/yyyy"
        isClearable
        maxDate={new Date()}
        customInput={
          <div className={styles.inputGroup}>
            <BiCalendarEvent className={styles.icon} />
            <input
              type="text"
              value={
                startDate && endDate
                  ? `${format(
                      startDate,
                      isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                    )} - ${format(
                      endDate,
                      isUSA ? USA_DATE_MONT_YEAR_SLASH : DATE_MONT_YEAR_SLASH
                    )}`
                  : ""
              }
              readOnly
              placeholder={t("FILTER.SINCE_TO")}
            />
          </div>
        }
      />
    </div>
  );
};

export default RangeDatePicker;
