import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import TableOrders from "@/components/orders/TableOrders/TableOrders";
import { useTranslation } from "react-i18next";
import SearchFilter from "@/components/orders/IdFilter/SearchFilter";

const OrdersPage = (): JSX.Element => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("orders-page");

  useEffect(() => setTitle(t("TITLE"), "", "", <SearchFilter />), [t]);

  return <TableOrders />;
};

export default OrdersPage;
