import RangeDatePicker from "../RangeDatePicker/RangeDatePicker";
import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import styles from "./styles.module.scss";
import useSWR from "swr";
import { getSubPurchaseListFilters } from "@/http";
import { useTranslation } from "react-i18next";
import { useSubPurchases } from "@/store/subpurchases";
import SortOrders from "../SortOrders";
import { SubPurchaseProcess } from "./SubPurchaseProcess";
import { SubpurchaseTableFilters } from "./SubpurchaseTableFilters";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash-es";

const OrderFilter = ({ mutate }: { mutate: () => void }) => {
  const [t] = useTranslation("global");
  const [search, setSearch] = useSearchParams();
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const { data: filters } = useSWR(
    ["subPurchasesFilters"],
    getSubPurchaseListFilters
  );
  const statusFilter: any = get(filters, "providerDeliveryStatusName", []);
  const { clearSelectedSubPurchases } = useSubPurchases();

  const actionFilter = (key: string, value: string) => {
    const finalValue = get(statusFilters, key, "") === value ? "" : value;
    const payload = {
      [key]: finalValue,
    };

    const newSearch = new URLSearchParams(search);

    newSearch.set("status", JSON.stringify(payload));
    newSearch.set("page", "0");
    setSearch(newSearch);
    clearSelectedSubPurchases();
  };

  return (
    <>
      <FilterTabs
        options={[
          ...Object.keys(statusFilter || {}).map((status: string) => ({
            text: statusFilter[status],
            value: status,
          })),
          { value: "", text: t("ALL") },
        ]}
        currentFilter={get(statusFilters, "providerDeliveryStatusName", "")}
        action={(value: string) =>
          actionFilter("providerDeliveryStatusName", value)
        }
      />

      <div className="flexGap">
        <div className={styles.containerFilters}>
          <SubPurchaseProcess mutate={mutate} />
          <RangeDatePicker />
          <SortOrders />
          <SubpurchaseTableFilters />
        </div>
      </div>
    </>
  );
};

export default OrderFilter;
