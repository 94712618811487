import { TSupplierVariantPP } from "@/models/order-details";
import { FormEditVariant } from "./FormDetailVariant";

type TEditVariantModal = {
  onDone?: (variant: TSupplierVariantPP) => void;
};

export const EditVariantModal = ({ onDone }: TEditVariantModal) => {
  return <FormEditVariant onDone={onDone} isSupplierBrand />;
};
