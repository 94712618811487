import { ReactNode } from "react";
import Paginate from "react-paginate";
import styles from "./styles.module.scss";
import useUrlState from "@ahooksjs/use-url-state";
import { PaginationSelect } from "@/models/orders";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { get } from "lodash-es";

type TPaginatedTable = {
  swrData: any;
  children: ReactNode;
  noScroll?: boolean;
  paginationKey?: string;
};

export const PaginatedTable = ({
  swrData,
  children,
  noScroll = false,
  paginationKey = "page",
}: TPaginatedTable) => {
  const [t] = useTranslation("global");
  const { data } = swrData;
  const [page, setPage] = useUrlState({ [paginationKey]: "0" });
  const pagination = get(page, paginationKey, 0);

  const handlePageChange = (selectedObject: PaginationSelect) => {
    setPage({ [paginationKey]: selectedObject.selected });
  };

  return (
    <>
      {data?.content?.length > 0 ? (
        <div className={clsx({ ["tableScroll"]: !noScroll })}>{children}</div>
      ) : (
        <div>
          <div className="spacer" />
          <p className="bold textCenter">{t("NO_DATA")}</p>
        </div>
      )}
      {Number(data?.totalPages ?? 0) > 1 && (
        <div className={clsx(styles.paginatorContainer, "pw1")}>
          <Paginate
            forcePage={Number(pagination)}
            pageCount={data.totalPages}
            previousLabel={"←"}
            nextLabel={"→"}
            marginPagesDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.previous}
            nextLinkClassName={styles.next}
            disabledClassName={styles.disabled}
            activeClassName={styles.selected}
            breakClassName={styles.break}
          />
        </div>
      )}
    </>
  );
};
