import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { TicketList } from "@/components/tickets/TicketList/TicketList";
import { useTranslation } from "react-i18next";
import { useSubscription } from "@/hooks/useSubscription";
import { LockedFeaturePlaceholder } from "../../components/subscription/LockedFeaturePlaceholder";
import { FEAT_TICKETS } from "@/constants/features";

const TicketsListPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { hasFeature } = useSubscription();

  useEffect(() => {
    setTitle(t("SIDEBAR.TICKETS"), "", "");
  }, [t]);

  if (!hasFeature(FEAT_TICKETS)) {
    return <LockedFeaturePlaceholder />;
  }

  return <TicketList />;
};

export default TicketsListPage;
