import { OrderDetailsPanel } from "@/components/order-details/InfoPanel/OrderDetailsPanel";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { useOrderDetails } from "@/store/order-detail";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductsDetails from "@/components/order-details/ProductsDetails/ProductsDetails";
import OrderDetailActions from "@/components/order-details/OrderDetailActions/OrderDetailActions";
import { CommentsSubPurchase } from "@/components/order-details/CommentsSubPurchase/CommentsSubPurchase";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { useHasInvoiceIntegration } from "@/hooks/useIntegrations";
import { ProductsDetailsByInvoice } from "@/components/order-details/ProductsDetails/ProductsDetailsByInvoice";

const PageOrderDetail = () => {
  const { id = "" } = useParams();
  const { setTitle } = useHeaderTitle();
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const { getOrderDetail, orderDetail, isLoading } = useOrderDetails();
  const isOwnOrder = user.id === orderDetail?.supplierId;

  const hasInvoicingIntegration = useHasInvoiceIntegration();
  const showGroupedByInvoice =
    user.manualInvoice &&
    hasInvoicingIntegration &&
    orderDetail.supplierStatus === "APPROVED";

  const getOrderDetailHook = async () => await getOrderDetail(id, user.id);

  useEffect(() => {
    getOrderDetailHook();
  }, [id]);
  useEffect(
    () =>
      setTitle(
        `${t("ORDERS.SINGLE_ORDER")} » ${
          orderDetail?.subPurchaseNumber || "-"
        }`,
        "/orders",
        ""
      ),
    [t, orderDetail]
  );

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      {!orderDetail ? (
        <h6>{t("NO_ORDER_DETAIL")}</h6>
      ) : (
        <>
          {isOwnOrder && <OrderDetailsPanel orderDetail={orderDetail} />}
          <OrderDetailActions orderDetail={orderDetail} userId={user.id} />
          {showGroupedByInvoice && isOwnOrder ? (
            <ProductsDetailsByInvoice orderDetail={orderDetail} />
          ) : (
            <ProductsDetails
              isSubpurchase
              orderDetail={orderDetail}
              onDoneSerials={getOrderDetailHook}
            />
          )}
          {isOwnOrder && <CommentsSubPurchase orderDetail={orderDetail} />}
        </>
      )}
    </>
  );
};

export default PageOrderDetail;
