import { useMemo, useState } from "react";
import useSWR from "swr";
import { toast } from "react-toastify";
import {
  TSupllierProduct,
  TSupplierOrderDetails,
} from "@/models/order-details";
import ProductsDetails from "./ProductsDetails";
import { groupBy } from "lodash-es";
import styles from "./styles.module.scss";
import {
  InvoicePayload,
  TPurchaseProduct,
  createInvoice,
  getInvoicePDFLink,
  getInvoiceTypes,
  getInvoices,
  isAxiosError,
} from "@/http";
import { Modal } from "@/components/shared/Modal/Modal";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { isUSA } from "@/constants/core";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { FormProvider, useForm } from "react-hook-form";
import { logError } from "@/utils/errors";
import { SelectV2 } from "@/components/shared/Input/SelectV2";

type Props = {
  orderDetail: TSupplierOrderDetails;
};

export const ProductsDetailsByInvoice = ({ orderDetail }: Props) => {
  const [t] = useTranslation("orders-page");

  const groupedProducts = useMemo(() => {
    const { undefined: invoiceless = [], ...groups } = groupBy(
      orderDetail.products,
      "invoiceId"
    );

    return Object.entries({ ...groups, invoiceless }).filter(
      ([, products]) => products.length > 0
    );
  }, [orderDetail]);

  return (
    <div className={styles.productsDetailsByInvoice}>
      {groupedProducts.map(([invoiceId, products]) => (
        <div key={invoiceId} className={styles.group}>
          <div className={styles.groupHeader}>
            {invoiceId === "invoiceless" ? (
              <>
                <h3 className={styles.groupHeading}>{t("NO_INVOICE")}</h3>
                <GenerateInvoiceAction
                  products={products}
                  subPurchaseId={orderDetail.id}
                />
              </>
            ) : (
              <>
                <InvoiceName
                  subPurchaseId={orderDetail.id}
                  invoiceId={invoiceId}
                />
                <DownloadInvoiceAction
                  invoiceId={invoiceId}
                  subPurchaseId={orderDetail.id}
                />
              </>
            )}
          </div>
          <div className={styles.tableContainer}>
            <ProductsDetails
              isSubpurchase
              orderDetail={{
                ...orderDetail,
                products,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const InvoiceName = ({
  invoiceId,
  subPurchaseId,
}: {
  invoiceId: string;
  subPurchaseId: string;
}) => {
  const [t] = useTranslation("orders-page");
  const { data } = useSWR([subPurchaseId, "invoices"], getInvoices);
  const invoice = data?.find(invoice => invoice.id === invoiceId);
  return (
    <h3 className={styles.groupHeading}>
      {t("INVOICE")} {invoice?.name}
    </h3>
  );
};

const GenerateInvoiceAction = ({
  products,
  subPurchaseId,
}: {
  products: (TPurchaseProduct & TSupllierProduct)[];
  subPurchaseId: string;
}) => {
  const { user } = useAuth();
  const [t] = useTranslation("orders-page");
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { getOrderDetail } = useOrderDetails();

  const onSubmit = async (values: any) => {
    const payload: InvoicePayload = {
      ...values,
      productIds: [...new Set(Object.values(values.productIds))],
    };

    if (!payload.productIds.length) {
      toast.warn(t("CHOOSE_ONE_PRODUCT") as string, {
        position: "bottom-center",
        toastId: "choose-at-least-one-product",
        autoClose: 2000,
      });
      return;
    }

    if (submitting) return;

    setSubmitting(true);

    try {
      await toast.promise(
        createInvoice(subPurchaseId, payload).then(() => {
          getOrderDetail(subPurchaseId, user.id);
          setOpen(false);
        }),
        {
          pending: t("GENERATING"),
          success: t("INVOICE_GENERATED"),
          error: {
            render(error) {
              let msg = t("ERROR_GENERATING_INVOICE");
              if (isAxiosError(error.data)) {
                msg = error.data.response?.data.message || msg;
              }
              return msg;
            },
          },
        }
      );
    } catch (error) {
      logError(error);
    }

    setSubmitting(false);
  };

  const { data: types, isValidating } = useSWR(
    [user.id, "invoice-types"],
    getInvoiceTypes
  );

  const methods = useForm<InvoicePayload>({});

  return (
    <>
      <button className="primary" onClick={() => setOpen(true)}>
        {t("GENERATE_INVOICE")}
      </button>

      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        size="sm"
        footer={
          <div className="rigthAlign">
            <button
              className="primary lg"
              disabled={submitting}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("GENERATE_INVOICE")}
            </button>
          </div>
        }
      >
        <h3 className="bolder">{t("GENERATE_INVOICE")}</h3>
        <div className="divider" />
        <FormProvider {...methods}>
          <form className={styles.generateInvoiceForm}>
            {!!types && types.length > 1 && (
              <SelectV2
                name="invoiceTypeId"
                label={t("INVOICE_TYPE")}
                required
                disabled={isValidating}
                choices={types?.map(t => ({
                  value: t.value,
                  label: t.text,
                }))}
              />
            )}

            <div>
              <p className="bold">{t("SELECT_INVOICE_PRODUCTS")}</p>
              <div className="spacer" />
              {products.map((product, index) => (
                <div key={product.id}>
                  <Checkbox
                    defaultChecked={true}
                    name={`productIds[${index}]`}
                    label={product.name}
                    key={product.id}
                    value={product.id}
                  />
                </div>
              ))}
            </div>

            {!isUSA && (
              <div>
                <p className="bold">{t("ADDITIONA_OPTIONS")}</p>
                <div className="spacer" />
                <Checkbox name="sendToTaxCollector" label={t("SEND_TAXES")} />
                <Checkbox
                  name="notifyClientByEmail"
                  label={t("NOTIFY_CLIENT_INVOICE")}
                />
              </div>
            )}
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

const DownloadInvoiceAction = ({
  invoiceId,
  subPurchaseId,
}: {
  invoiceId: string;
  subPurchaseId: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("orders-page");
  const [tGlobal] = useTranslation("global");

  const handleClick = async () => {
    setLoading(true);
    try {
      await toast.promise(getInvoicePDFLink(subPurchaseId, invoiceId), {
        pending: t("GENERATING_LINK") as string,
        success: {
          render: ({ data, closeToast }) => {
            return (
              <>
                {t("LINK")}:{" "}
                <a
                  className={clsx(styles.downloadLink)}
                  href={data!.url}
                  download={invoiceId + ".pdf"}
                  target="_blank"
                  rel="noreferrer"
                  onClick={closeToast}
                >
                  {tGlobal(isUSA ? "DOWNLOAD_INVOICE" : "DOWNLOAD_PDF")}
                </a>
              </>
            );
          },
          autoClose: false,
          closeOnClick: false,
        },
        error: t("ERROR_FILE") as string,
      });
    } catch (error) {
      logError(error);
    }
    setLoading(false);
  };
  return (
    <button onClick={handleClick} className="primary" disabled={loading}>
      {t("DOWNLOAD_INVOICE")}
    </button>
  );
};
