import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import { PremiumFeatureIndicator } from "@/components/subscription/PremiumFeatureIndicator";
import { FEAT_SELL_IN } from "@/constants/features";

export const ProductsTableActions = ({
  mutate,
}: {
  mutate: (...args: any) => void;
}) => {
  const { deleteProducts, selectedProducts } = useProducts();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [t] = useTranslation("products-page");

  if (Object.keys(selectedProducts).length <= 0) {
    return null;
  }

  return (
    <div className={`${styles.tableActions} contentInline`}>
      <div></div>
      <div className={styles.actions}>
        <button
          data-testid="deleteProductsButton"
          className="outlined"
          onClick={() => deleteProducts(user.id, mutate)}
        >
          {t("DELETE_PRODUCTS")}
        </button>
        <button
          className="primary"
          onClick={() => navigate("/acquisitions/create?from=products")}
        >
          {t("GO_TO_PURCHASE")}{" "}
          <PremiumFeatureIndicator feature={FEAT_SELL_IN} />
        </button>

        <button
          className="primary"
          onClick={() => navigate("/purchases/create?from=products")}
        >
          {t("GO_TO_SALE")}
        </button>
      </div>
    </div>
  );
};
