import { ProviderLogisticDispatchForm } from "@/components/order-details/Dispatch/ProviderLogisticDispatchForm";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { Modal } from "@/components/shared/Modal/Modal";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_NO_SPACES, USA_DATE_HOUR_NO_SPACES } from "@/constants/date";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  getSubPurchaseMultipleInvoice,
  updateMultipleTrackingCodeDeliveryProvider,
} from "@/http";
import { getSupplierDeliveryProviders } from "@/http/resources/suppliers/delivery-provider";
import { useSubPurchases } from "@/store/subpurchases";
import { saveFile } from "@/utils/save-file";
import clsx from "clsx";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import styles from "./styles.module.scss";

export const SubPurchaseProcess = ({ mutate }: { mutate: () => void }) => {
  const [t] = useTranslation("global");
  const { selectedSubPurchases, clearSelectedSubPurchases } = useSubPurchases();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const { data: deliveryProviders } = useSWR(
    ["supplier-delivery-providers"],
    getSupplierDeliveryProviders
  );

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const purchasesIds = Object.keys(selectedSubPurchases || {});

      const {
        hasComments = false,
        hasTotal = false,
        downloadRemission = false,
        providerDeliveryStatus = "",
        dispatchValue = "",
        moveDeliveryStatus = false,
      } = values;
      if (!downloadRemission && !moveDeliveryStatus) {
        showToastError(t("SELECT_OPTION"));
        return;
      }

      if (downloadRemission) {
        const blob = await getSubPurchaseMultipleInvoice({
          hasComments,
          hasTotal,
          ids: purchasesIds,
        });

        await saveFile(
          blob,
          `multiple_dispatch_orders_remission_${format(
            new Date(),
            isUSA ? USA_DATE_HOUR_NO_SPACES : DATE_HOUR_NO_SPACES
          )}.pdf`
        );
      }

      if (moveDeliveryStatus) {
        const payload = purchasesIds.map((subPurchaseId: string) => ({
          subPurchaseId,
          deliveryProvider: dispatchValue,
          providerDeliveryStatus,
        }));
        await updateMultipleTrackingCodeDeliveryProvider(payload);
        mutate();
        showToastSuccess(t("ORDERS_UPDATED"));
      }

      clearSelectedSubPurchases();
      onClose();
    } catch (e) {
      showToastError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    methods.setValue("providerDeliveryStatus", "");
  }, [methods.watch("dispatchValue")]);

  return (
    <>
      <DisabledZone when={!Object.keys(selectedSubPurchases || {}).length}>
        <div
          onClick={() => setIsOpen(true)}
          className={clsx(
            "flexGap center bold contentInline pointer",
            styles.schedule,
            {
              [styles.disabled]: !Object.keys(selectedSubPurchases || {})
                .length,
            }
          )}
        >
          <span>{t("ACTIONS")}</span>
        </div>
      </DisabledZone>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={onClose}
        title={t("ACTIONS")}
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("EXECUTE")}
            </button>
          </>
        }
      >
        <>
          <FormProvider {...methods}>
            <div className="spacer" />
            <Checkbox
              name="downloadRemission"
              label={t("MULTIPLE_REMISSION_PURCHASE")}
            />
            {methods.watch("downloadRemission") && (
              <div className="ml1">
                <Checkbox name="hasComments" label={t("INVOICE_COMMENTS")} />
                <Checkbox name="hasTotal" label={t("INVOICE_TOTAL")} />
              </div>
            )}

            {(deliveryProviders || [])?.length > 0 && (
              <Checkbox
                name="moveDeliveryStatus"
                label={t("MOVE_DELIVERY_STATUS")}
              />
            )}

            {methods.watch("moveDeliveryStatus") && (
              <div className="ml1">
                <p>{t("DELIVERY_PROVIDER")}</p>
                <RadioGroupV2
                  label={""}
                  name="dispatchValue"
                  options={(deliveryProviders ?? []).map(dp => ({
                    label: dp.name,
                    value: dp.code,
                  }))}
                  required
                  inline
                  color="purple"
                />
                <div className="spacer" />

                {!!methods.watch("dispatchValue") &&
                  !["SEERI", "SUPPLIER"].includes(
                    methods.watch("dispatchValue")
                  ) && (
                    <ProviderLogisticDispatchForm
                      setIsLoading={setIsLoading}
                      setUsedTrackingCode={() => {
                        return;
                      }}
                      usedTrackingCode={false}
                      files={[]}
                      setFiles={() => {
                        return;
                      }}
                      simpleForm
                    />
                  )}
              </div>
            )}
          </FormProvider>
        </>
      </Modal>
    </>
  );
};
