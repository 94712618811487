import { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from "react";
import {
  FieldError,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

export type InputProps = {
  name: string;
  label: string | ReactNode;
  type?: "text" | "email" | "password" | "number";
  placeholder?: string;
  register: UseFormRegister<FieldValues | any>;
  required?: boolean;
  error?: (FieldError & { length?: string }) | Record<string, any>;
  onChange?: (...params: any[]) => void;
  options?: RegisterOptions<any, string>;
  min?: number;
  max?: number;
  choices?: {
    value: string;
    label: string | ReactNode;
    disabled?: boolean;
    selected?: boolean;
  }[];
  validate?: any;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  onKeyPress?: any;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

export const Input = ({
  name,
  label,
  type,
  placeholder,
  register,
  error,
  required = false,
  onChange,
  options,
  min,
  max,
  validate,
  maxLength,
  minLength,
  onKeyPress,
  inputProps,
}: InputProps) => {
  const [t] = useTranslation("global");

  return (
    <div className={`${styles.field} ${error ? styles.required : ""}`}>
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        id={name}
        placeholder={placeholder}
        {...register(name, {
          required: required ? t("LOGIN.FIELD_REQUIRED") : false,
          onChange: onChange,
          validate,
          ...options,
        })}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        autoComplete="off"
        data-testid={name}
        {...inputProps}
      />
      {error?.type === "required" ? (
        <span className={styles.errorsLabel}>{t("LOGIN.FIELD_REQUIRED")}</span>
      ) : error?.type === "validate" ? (
        <span className={styles.errorsLabel}>{t("LOGIN.INVALID_FIELD")}</span>
      ) : error?.message ? (
        <span className={styles.errorsLabel}>{error.message}</span>
      ) : null}
    </div>
  );
};

export default Input;
