import { Modal } from "@/components/shared/Modal/Modal";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getSubPurchaseInvoicePDF,
  TSubPurchase,
  updateTrackingCodeDeliveryProvider,
} from "@/http";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { isUSA } from "@/constants/core";
import { useCompletePurchase } from "@/hooks/usePurchaseProducts";
import { get } from "lodash-es";
import useSWR from "swr";
import { getSupplierDeliveryProviders } from "@/http/resources/suppliers/delivery-provider";
import { ProviderLogisticDispatchForm } from "@/components/order-details/Dispatch/ProviderLogisticDispatchForm";
import { RadioGroupV2 } from "@/components/shared/RadioGroup/RadioGroupV2";
import { useOrderDetails } from "@/store/order-detail";
import { saveFile } from "@/utils/save-file";

type TDownloadInvoicePDF = {
  useIconButton?: boolean;
  subpurchase: TSubPurchase;
};

export const SubPurchaseInvoicePDF = ({
  useIconButton = false,
  subpurchase,
}: TDownloadInvoicePDF) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setOrderDetail } = useOrderDetails();
  const purchase = useCompletePurchase(subpurchase.purchaseId ?? "");
  const comments = get(purchase, "comments", []);

  const { data: deliveryProviders } = useSWR(
    ["supplier-delivery-providers"],
    getSupplierDeliveryProviders
  );

  const defaultValues: any = {
    hasComments: false,
    hasTotal: false,
    moveDeliveryStatus: false,
  };
  const methods = useForm({ defaultValues });
  const onClose = () => {
    setIsOpen(false);
    methods.reset(defaultValues);
  };

  const onSubmit = async (values: typeof defaultValues) => {
    try {
      setIsLoading(true);
      const {
        hasComments,
        hasTotal,
        providerDeliveryStatus = "",
        dispatchValue = "",
        moveDeliveryStatus,
      } = values;

      if (moveDeliveryStatus) {
        const supplierOrder = (await updateTrackingCodeDeliveryProvider(
          subpurchase.id,
          subpurchase.subPurchaseNumber,
          "",
          dispatchValue,
          providerDeliveryStatus
        )) as any;
        setOrderDetail(supplierOrder);
        showToastSuccess(t("ORDER_UPDATED"));
      }

      const file = await getSubPurchaseInvoicePDF(subpurchase.id, {
        hasComments,
        hasTotal,
      });

      await saveFile(
        file,
        subpurchase.subPurchaseNumber + (isUSA ? "_packing_slip" : "_remision")
      );

      onClose();
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    methods.setValue("providerDeliveryStatus", "");
  }, [methods.watch("dispatchValue")]);

  return (
    <div>
      {useIconButton ? (
        <ToolTip title={t("DOWNLOAD_PACKING_SLIP")}>
          <span
            className="circleButton pointer"
            onClick={() => setIsOpen(true)}
          >
            <LiaFileDownloadSolid />
          </span>
        </ToolTip>
      ) : (
        <button className="outlined" onClick={() => setIsOpen(true)}>
          {t("DOWNLOAD_PACKING_SLIP")}
        </button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t("DOWNLOAD_PACKING_SLIP")}
        size="sm"
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t("DOWNLOAD")}
            </button>
          </>
        }
      >
        <div className="mtmd" />
        <FormProvider {...methods}>
          {(comments?.length || 0) > 0 && (
            <Checkbox name="hasComments" label={t("INVOICE_COMMENTS")} />
          )}
          <Checkbox name="hasTotal" label={t("INVOICE_TOTAL")} />

          {(deliveryProviders || [])?.length > 0 &&
            subpurchase.deliveryStatus === "PENDING" && (
              <Checkbox
                name="moveDeliveryStatus"
                label={t("MOVE_DELIVERY_STATUS")}
              />
            )}

          {methods.watch("moveDeliveryStatus") && (
            <>
              <p>{t("DELIVERY_PROVIDER")}</p>
              <RadioGroupV2
                label={""}
                name="dispatchValue"
                options={(deliveryProviders ?? []).map(dp => ({
                  label: dp.name,
                  value: dp.code,
                }))}
                required
                inline
                color="purple"
              />
              <div className="spacer" />

              {!!methods.watch("dispatchValue") &&
                !["SEERI", "SUPPLIER"].includes(
                  methods.watch("dispatchValue")
                ) && (
                  <ProviderLogisticDispatchForm
                    setIsLoading={setIsLoading}
                    setUsedTrackingCode={() => {
                      return;
                    }}
                    usedTrackingCode={false}
                    files={[]}
                    setFiles={() => {
                      return;
                    }}
                    simpleForm
                  />
                )}
            </>
          )}

          <div className="spacer" />
        </FormProvider>
      </Modal>
    </div>
  );
};
