import { useAvailablePaymentMethods } from "@/hooks/usePurchaseProducts";
import {
  createPurchasePayment,
  deletePurchasePayment,
  TPurchase,
  updatePurchasePayment,
} from "@/http";
import { usePurchase } from "@/store/purchase";
import { debounce } from "lodash-es";
import { FormProvider, useForm } from "react-hook-form";
import { SalePaymentDetail } from "../../SaleCreation/Payments/SalePaymentDetail";
import { SalePaymentsForm } from "../../SaleCreation/Payments/SalePaymentsForm";
import { SaleTotalPaid } from "../../SaleCreation/Payments/SaleTotalPaid";

export const PaymentsList = ({ purchase }: { purchase: TPurchase }) => {
  const { payments = [] } = purchase;
  const { getPurchase } = usePurchase();
  const methods = useForm();
  const paymentMethods = useAvailablePaymentMethods(purchase.id);

  const debouncedGetPurchase = debounce((id: string) => getPurchase(id), 1300);

  const onEdit = async (values: any) => {
    await updatePurchasePayment(purchase.id, values.id, values);
    debouncedGetPurchase(purchase.id);
  };

  const onDelete = async (values: any) => {
    await deletePurchasePayment(purchase.id, values.id);
    debouncedGetPurchase(purchase.id);
  };

  const onCreate = async (values: any) => {
    await createPurchasePayment(purchase.id, values);
    debouncedGetPurchase(purchase.id);
  };

  return (
    <>
      <SalePaymentsForm
        paymentMethods={paymentMethods}
        payments={payments}
        total={purchase.total ?? 0}
        onDone={onCreate}
        coins={0}
      />
      {(payments || []).map((p: any, idx: number) => (
        <SalePaymentDetail
          payment={p}
          key={idx}
          paymentMethods={paymentMethods}
          payments={payments}
          coins={0}
          total={purchase?.total ?? 0}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
      <FormProvider {...methods}>
        <div className="spacer" />
        <SaleTotalPaid
          coins={0}
          total={purchase?.total ?? 0}
          payments={payments}
        />
        <div className="spacer" />
      </FormProvider>
    </>
  );
};
