import { paymentStatus, purchaseStatus } from "@/constants/purchases";
import { QUOTED } from "@/http";
import { useTranslation } from "react-i18next";
import { TPurchaseShow } from "../PurchaseShow";
import styles from "./styles.module.scss";

export const PurchaseStatusInfo = ({ purchase }: TPurchaseShow) => {
  const [t] = useTranslation("purchases");

  return (
    <div>
      <div className={styles.field}>
        <span className={styles.title}>{t("STATUS")}:</span>
        <span>
          {purchaseStatus.find(s => s.code === purchase.status)?.name}
        </span>
      </div>

      {purchase.status != QUOTED && (
        <>
          <div className={styles.field}>
            <span className={styles.title}>{t("SHIP")}:</span>
            <span>{purchase.providerDeliveryStatusName}</span>
          </div>

          <div className={`${styles.field} centerVAlign`}>
            <span className={styles.title}>{t("PAYMENT")}:</span>
            <span>
              {paymentStatus.find(s => s.code === purchase.paymentStatus)?.name}
            </span>
          </div>
        </>
      )}

      {!!purchase.shippingMethod && (
        <div className={styles.field}>
          <span className={styles.title}>{t("SHIPPING_METHOD")}:</span>
          <span className="textLine">{t(purchase?.shippingMethod)}</span>
        </div>
      )}
    </div>
  );
};
