import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { get } from "lodash-es";
import styles from "./styles.module.scss";

type InputProps = {
  name: string;
  label: string | ReactNode;
  rows: number;
  placeholder?: string;
  required?: boolean;
  onChange?: (...params: any[]) => void;
  validate?: any;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  onKeyPress?: any;
  validMessage?: string;
  infoLabel?: string;
};

export const TextArea = ({
  name,
  label,
  placeholder,
  required = false,
  onChange,
  validate,
  maxLength,
  minLength,
  onKeyPress,
  disabled,
  validMessage,
  infoLabel,
  rows,
}: InputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [t] = useTranslation("global");
  const typeError = get(errors, `${name}.type`);

  return (
    <div className={`${styles.field} ${typeError ? styles.required : ""}`}>
      <label htmlFor={name}>{label}</label>
      <textarea
        id={name}
        data-testid={name}
        placeholder={placeholder}
        {...register(name, {
          required: required ? t("LOGIN.FIELD_REQUIRED") : false,
          onChange: onChange,
          validate,
        })}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
        rows={rows}
        autoComplete="off"
      />
      {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}
      {typeof typeError === "string" && typeError === "required" && (
        <span className={styles.errorsLabel}>{t("LOGIN.FIELD_REQUIRED")}</span>
      )}
      {typeof typeError === "string" && typeError === "validate" && (
        <span className={styles.errorsLabel}>
          {validMessage || t("LOGIN.INVALID_FIELD")}
        </span>
      )}
    </div>
  );
};
