import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { GoSortAsc } from "react-icons/go";
import { useSearchParams } from "react-router-dom";
import { MultipleMenu } from "../shared/Menu/MultipleMenu";
import styles from "./styles.module.scss";

const SortOrders = () => {
  const [search, setSearch] = useSearchParams(
    "sortBy=createdAt&sortDirection=DESC"
  );
  const sortBy = search.get("sortBy");
  const sortDirection = search.get("sortDirection");

  const [t] = useTranslation("orders-page");

  const setSortBy = (sortBy: string | string[]) => {
    if (typeof sortBy === "string") {
      const newSearch = new URLSearchParams(search);
      newSearch.set("sortBy", sortBy);
      setSearch(newSearch);
    }
  };
  const setSortDirection = (sortDirection: string | string[]) => {
    if (typeof sortDirection === "string") {
      const newSearch = new URLSearchParams(search);
      newSearch.set("sortDirection", sortDirection ?? "");
      setSearch(newSearch);
    }
  };

  const sortValues: Record<string, string> = {
    subPurchaseNumber: "ID",
    createdAt: "DATE",
    priority: "PRIORITY",
  };

  return (
    <>
      <MultipleMenu
        width="15rem"
        label={
          <div className="col-12 noPadding">
            <p className="mdFont flexGap center">
              <GoSortAsc />
              {t("SORT.TITLE")}
            </p>
            <div className={clsx(styles.filterContainer, "contentInline")}>
              <span>
                {sortBy && t(`SORT.${sortValues[sortBy]}`)}
                {sortDirection && ` - ${t(`SORT.${sortDirection}`)}`}
              </span>
              <span>
                <FaChevronDown size={"0.7rem"} />
              </span>
            </div>
          </div>
        }
        values={[
          {
            onSelect: setSortBy,
            title: t("PROPERTY"),
            selectedValue: sortBy,
            options: [
              { label: t("SORT.ID"), value: "subPurchaseNumber" },
              { label: t("SORT.DATE"), value: "createdAt" },
              { label: t("SORT.PRIORITY"), value: "priority" },
            ],
          },
          {
            onSelect: setSortDirection,
            title: t("ORDER"),
            selectedValue: sortDirection,
            options: [
              { label: t("SORT.ASC"), value: "ASC" },
              { label: t("SORT.DESC"), value: "DESC" },
            ],
          },
        ]}
      />
    </>
  );
};

export default SortOrders;
