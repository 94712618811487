import { useTranslation } from "react-i18next";
import { ChangeEvent, useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./styles.module.scss";
import { useSubPurchases } from "@/store/subpurchases";
import { useSearchParams } from "react-router-dom";

const SearchFilter = () => {
  const [search, setSearch] = useSearchParams();
  const filter = search.get("filter");
  const debounceRef = useRef<any>();
  const { clearSelectedSubPurchases } = useSubPurchases();

  const onQueryChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === filter) {
      return;
    }
    const newSearch = new URLSearchParams(search);
    newSearch.set("filter", event.target.value);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      newSearch.set("page", "0");
      setSearch(newSearch);
      clearSelectedSubPurchases();
    }, 350);
  };

  const [t] = useTranslation("orders-page");

  return (
    <>
      <div className={styles.inputGroup}>
        <AiOutlineSearch className={styles.icon} />
        <input
          autoComplete={"off"}
          type="text"
          placeholder={t("SEARCH")}
          defaultValue={filter ?? ""}
          onChange={onQueryChanged}
        />
      </div>
    </>
  );
};

export default SearchFilter;
