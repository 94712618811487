import { create } from "zustand";

type TUsePurchases = {
  selectedPurchases: Record<string, any>;
  setSelectedPurchases: (product: any) => void;
  clearSelectedPurchases: () => void;
};
export const usePurchases = create<TUsePurchases>((set, get) => ({
  selectedPurchases: {},
  clearSelectedPurchases: () => {
    set({ selectedPurchases: {} });
  },
  setSelectedPurchases: (value: any) => {
    const currentPurchases = { ...get().selectedPurchases };
    if (currentPurchases[value.id]) {
      delete currentPurchases[value.id];
      set({
        selectedPurchases: currentPurchases,
      });
    } else {
      set({
        selectedPurchases: { ...currentPurchases, [value.id]: value },
      });
    }
  },
}));
