import { TLoginForm } from "@/models/auth";
import { FormProvider, useForm } from "react-hook-form";
import { FirebaseError } from "firebase/app";
import { customToast } from "@/hooks/useToast";
import { useAuth } from "@/store/auth";
import { useTranslation } from "react-i18next";
import { AxiosError } from "@/http";
import styles from "./styles.module.scss";
import Input from "@/components/shared/Input/Input";
import { SupplierRegister } from "@/components/login/SupplierRegister";
import { OTPLogin } from "@/components/login/OTPLogin";
import { useTitle } from "ahooks";

export const Login: React.FC = () => {
  const [t] = useTranslation("global");
  useTitle(t("LOGIN.LOGIN_BUTTON") + " | Portal Seeri");

  const methods = useForm<TLoginForm>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { login } = useAuth();

  const onSubmit = async (values: TLoginForm) => {
    try {
      await login(values);
    } catch (error) {
      if (error instanceof FirebaseError) {
        return customToast({
          title: t("LOGIN.INVALID_LOGIN"),
          status: "warn",
        });
      }

      let errorMessage = t("LOGIN.ERROR_LOGIN");

      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.message;
      }

      customToast({
        status: "warn",
        title: errorMessage,
      });
    }
  };

  return (
    <div className={styles.loginContainer}>
      <section className={styles.content}>
        <div className={styles.logo}>
          <img src="/logo_blue.svg" alt="seeri logo" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <h6 className={styles.title}>{t("LOGIN.WELCOME_AGAIN")}</h6>
            <p className={styles.description}>
              {t("LOGIN.ENJOY_YOUR_BENEFITS")}
            </p>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.inputs}
                data-testid="loginForm"
              >
                <Input
                  name="email"
                  label={t("LOGIN.EMAIL")}
                  type="email"
                  placeholder={t("LOGIN.EMAIL_PLACEHOLDER")}
                  register={register}
                  required={true}
                  error={errors.email}
                  min={1}
                />
                <Input
                  name="password"
                  label={t("LOGIN.PASSWORD")}
                  type="password"
                  placeholder={t("LOGIN.PASSWORD_PLACEHOLDER")}
                  register={register}
                  required={true}
                  error={errors.password}
                  min={1}
                />
                <div className={styles.actions}>
                  <button
                    className={`primary ${styles.buttonLogin}`}
                    disabled={isSubmitting}
                    type="submit"
                    data-testid="loginButton"
                  >
                    {t("LOGIN.LOGIN_BUTTON")}
                  </button>
                </div>
              </form>

              <OTPLogin />
            </FormProvider>
            <SupplierRegister />
          </div>
        </div>
        <div className={styles.rights}>{t("LOGIN.RIGHTS")}</div>
      </section>
    </div>
  );
};
