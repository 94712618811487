import { ReactNode, useEffect, useState } from "react";
import Select from "react-select";
import { getProductSerials, TProductSerial } from "@/http";
import { useTranslation } from "react-i18next";
import {
  selectMultipleStyles,
  selectMultipleStylesSlim,
} from "@/constants/input";
import useSWR from "swr";
import { Controller, useFormContext } from "react-hook-form";
import { get } from "lodash-es";
import styles from "./styles.module.scss";
import clsx from "clsx";

type TMultipleSelectionSerial = {
  disabled?: boolean;
  required?: boolean;
  infoLabel?: string | ReactNode;
  name: string;
  productId: string;
  label?: ReactNode;
  purchaseId?: string;
  maxOptions?: number;
  slim?: boolean;
};

export const MultipleSelectionSerial = ({
  disabled,
  required,
  infoLabel,
  name,
  productId,
  label,
  purchaseId,
  maxOptions = 0,
  slim = false,
}: TMultipleSelectionSerial) => {
  const [t] = useTranslation("global");
  const [options, setOptions] = useState<any[]>([]);
  const { data, mutate } = useSWR(
    [
      productId,
      { size: 1000, page: 0, sort: "createdAt,desc" },
      "productSerials",
    ],
    getProductSerials
  );
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const serials = get(data, "content", []);

  useEffect(() => {
    const formattedOptions = serials
      .filter((s: TProductSerial) => {
        return purchaseId
          ? !s.usedInPurchase || s.usedInPurchase === purchaseId
          : s;
      })
      .map((s: TProductSerial) => ({
        value: s.id,
        label: s.serialNumber,
      }));

    if (JSON.stringify(options) !== JSON.stringify(formattedOptions)) {
      setOptions(formattedOptions);
    }
  }, [serials, options, purchaseId]);

  useEffect(() => {
    mutate();
  }, [watch(name), productId]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? t("FIELD_REQUIRED") : undefined,
        validate: (value?: any[]) => {
          return (value || []).length <= maxOptions
            ? true
            : t("MAX_OPTIONS_EXCEEDED");
        },
      }}
      render={({ field: { onChange, value } }) => (
        <div
          className={clsx(styles.field, styles.noInputBorder, {
            [styles.required]: get(errors, name),
          })}
        >
          {label && <p className={styles.label}>{label}</p>}
          <Select
            isMulti
            options={options}
            value={options?.filter(opt => value?.includes(opt.value))}
            onChange={selected => {
              const selectedValues = selected.map(opt => opt.value);
              if (selectedValues.length <= maxOptions) {
                onChange(selectedValues);
              }
            }}
            isDisabled={disabled}
            placeholder={t("SELECT")}
            styles={slim ? selectMultipleStylesSlim : selectMultipleStyles}
            menuPortalTarget={document.body}
            noOptionsMessage={() => t("NO_OPTIONS")}
            isClearable={false}
          />
          {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}
          {!!get(errors, name) && (
            <span className={styles.errorsLabel}>
              {get(errors as any, name).message}
            </span>
          )}
        </div>
      )}
    />
  );
};
