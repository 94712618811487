import { InputV2 } from "@/components/shared/Input/InputV2";
import { NEGOTIATION_TYPE } from "@/constants/supplier";
import { useIsBrandOwner } from "@/hooks/useProducts";
import { useAuth } from "@/store/auth";
import { validateDecimalNumber } from "@/utils/keyboard";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type TVariantsPricesInputs = {
  brandId: string;
  disabled?: boolean;
};
export const VariantsPricesInputs = ({
  brandId = "",
  disabled = false,
}: TVariantsPricesInputs) => {
  const [t] = useTranslation("products-page");
  const { user } = useAuth();
  const negotiationType = user.negotiationType;
  const { FIXED_MARGIN, VARIABLE_MARGIN, PRICE_LIST } = NEGOTIATION_TYPE;
  const isSupplierBrand = useIsBrandOwner(brandId, user.id);

  return (
    <>
      <div className="col-12 noPadding">
        <h1 className={`${styles.inCol} bold`}>{t("PRODUCT.PRICE")}</h1>
        <div className="row noPadding">
          {[FIXED_MARGIN, VARIABLE_MARGIN].includes(negotiationType) && (
            <div className={`col-6 col-sm-12 noPaddingH`}>
              <InputV2
                name="wholesalePrice"
                required
                label={t("PRODUCT.PRICE")}
                type="text"
                onKeyPress={validateDecimalNumber}
                disabled={disabled}
              />
            </div>
          )}
          {[PRICE_LIST].includes(negotiationType) && (
            <div className={`col-6 col-sm-12 noPaddingH`}>
              <InputV2
                name="supplierPrice"
                required
                label={t("PRODUCT.PRICE")}
                type="text"
                onKeyPress={validateDecimalNumber}
                disabled={disabled}
              />
            </div>
          )}

          {isSupplierBrand && (
            <div className={`col-6 col-sm-12 noPaddingH`}>
              <InputV2
                name="retailPrice"
                label={<span className="textLine">{t("PVP")}</span>}
                type="text"
                onKeyPress={validateDecimalNumber}
                disabled={disabled}
              />
            </div>
          )}
          <div className={`col-6 col-sm-12 noPaddingH`}>
            <InputV2
              name="purchasePrice"
              label={<span className="textLine">{t("PURCHASE_PRICE")}</span>}
              type="text"
              onKeyPress={validateDecimalNumber}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};
