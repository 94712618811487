import { ClientModal } from "@/components/clients/ClientModal";
import { InputV2 } from "@/components/shared/Input/InputV2";
import { isUSA } from "@/constants/core";
import { TSeller, getSellers } from "@/http";
import { useAuth } from "@/store/auth";
import { getSupplierSellerFromSuppliers } from "@/utils/sellers";
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { SellerResume } from "../SellerResume/SellerResume";
import clsx from "clsx";
import { MdOutlineEdit } from "react-icons/md";
import { hasPermission } from "@/utils/validations/permissions";

type TSellerInput = {
  sellerInput?: TSeller;
  setDone?: () => void;
  hideEditSeller?: boolean;
  allowCreate?: boolean;
  inputLabel?: ReactNode;
};

export const SellerInputSearch = ({
  setDone,
  sellerInput,
  hideEditSeller,
  allowCreate = false,
  inputLabel,
}: TSellerInput) => {
  const { setValue, trigger, watch } = useFormContext();
  const [t] = useTranslation("purchases");
  const { user, isCommercial, commercial, role } = useAuth();
  const [options, setOptions] = useState<TSeller[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const debounceRef = useRef<Record<string, any>>({});
  const optionsRef = useRef<HTMLDivElement>(null);
  const fixedDivRef = useRef<HTMLDivElement>(null);
  const searchTypeWatch = watch("sellerSearchType");
  const seller = watch("seller", sellerInput);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (debounceRef.current.sellerName) {
      clearTimeout(debounceRef.current.sellerName);
    }

    debounceRef.current.sellerName = setTimeout(() => {
      getFilterSellers(event.target.value);
    }, 250);
  };

  const getFilterSellers = async (value: string) => {
    const { content } = await getSellers({
      search: `text:${value}`,
    });
    setOptions(content);
    setShowOptions(true);
  };

  const setSeller = (seller: TSeller | undefined) => {
    setValue("seller", seller);
    setValue("sellerId", seller?.id);
    trigger(["sellerName", "sellerSearchType"]);
    setShowOptions(false);
    setDone?.();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue("sellerName", "");
        setValue("seller", {});
        setShowOptions(false);
      }
    };

    const handleOutsideScroll = (event: Event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setValue("seller", {});
        setValue("sellerName", "");
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("wheel", handleOutsideScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("wheel", handleOutsideScroll);
    };
  }, [showOptions]);

  useEffect(() => {
    const sellerName = watch("sellerName");
    if (sellerName) {
      getFilterSellers(sellerName);
    }
  }, [searchTypeWatch]);
  return (
    <div className={styles.containerSeller}>
      <div ref={fixedDivRef} className={styles.inputs}>
        {!seller || !seller?.id ? (
          <InputV2
            name="sellerName"
            placeholder={t("SELLER_SEARCH")}
            label={
              <div className="contentInline">
                {inputLabel}
                <ClientModal
                  hideButton={!allowCreate}
                  smallIconButton
                  onDone={client => {
                    setSeller(client as any);
                  }}
                />
              </div>
            }
            onChange={e => onInputChange(e)}
            required
          />
        ) : (
          <>
            <div className={styles.containerResult}>
              {!hideEditSeller && (
                <button
                  className={clsx("pointer", "circle", styles.close)}
                  onClick={e => {
                    e.preventDefault();
                    setSeller(undefined);
                  }}
                >
                  <div className="pointer circleButton">
                    <MdOutlineEdit />
                  </div>
                </button>
              )}

              {<SellerResume seller={seller} hideEdit />}
            </div>
          </>
        )}
      </div>

      {showOptions &&
        createPortal(
          <div
            ref={optionsRef}
            style={{
              top: fixedDivRef.current
                ? `${
                    fixedDivRef.current.getBoundingClientRect().bottom +
                    5 +
                    (window.pageYOffset || document.documentElement.scrollTop)
                  }px`
                : "0",
              width: fixedDivRef.current
                ? `${fixedDivRef.current.getBoundingClientRect().width}px`
                : "100%",
              left: fixedDivRef.current
                ? `${fixedDivRef.current.getBoundingClientRect().left}px`
                : "100%",
            }}
            className={styles.list}
          >
            <ul>
              {options.map(option => {
                const supplierSeller = getSupplierSellerFromSuppliers(
                  option.suppliers || [],
                  user.id
                );
                const isValidSeller = hasPermission(isCommercial, role, [
                  "action.sales.all-clients",
                ])
                  ? true
                  : isCommercial && Boolean(supplierSeller?.commercialId)
                  ? supplierSeller?.commercialId === commercial?.id
                  : true;

                return (
                  <li
                    key={option.id}
                    data-testid={option.email}
                    onClick={() => {
                      if (isValidSeller) {
                        setSeller(option);
                      }
                    }}
                    className={!isValidSeller ? styles.disabledSeller : ""}
                  >
                    {`${isUSA ? option.legalName + " - " : ""} ${
                      option.firstName
                    } ${!isUSA ? option.lastName : ""}`}

                    {!isValidSeller && (
                      <>
                        <br />
                        <span className={styles.email}>
                          * {t("USER_NOT_AVAILABLE")}
                        </span>
                      </>
                    )}

                    {!isUSA && (
                      <>
                        <br />
                        <span className={styles.email}>
                          {t("DOCUMENT_NUMBER")}: {option.documentId}
                        </span>
                      </>
                    )}
                    {!option?.email?.endsWith("@noemail.com") && (
                      <>
                        <br />
                        <span className={styles.email}>
                          {t("SELLER_BILLING_EMAIL")}: {option.email}
                        </span>
                      </>
                    )}
                  </li>
                );
              })}
              {!options?.length && <li>{t("NO_OPTIONS")}</li>}
            </ul>
          </div>,
          document.body
        )}
    </div>
  );
};
