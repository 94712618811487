import AsyncSelect from "react-select/async";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { searchProductsV4 } from "@/http";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/store/auth";
import { InputV2 } from "@/components/shared/Input/InputV2";
import styles from "./styles.module.scss";
import { FaTimes } from "react-icons/fa";
import { ProductConsumer } from "@/components/shared/ProductConsumer";
import { singleSelectStyles } from "@/constants/input";
import clsx from "clsx";
import { debounce } from "lodash-es";

type KitProduct = {
  id: string;
  quantity: number;
};

type KitProductOption = {
  label: string;
  value: string;
  disabled: boolean;
};

export const KitProductsInput = () => {
  const { user } = useAuth();
  const [tGlobal] = useTranslation("global");
  const [t] = useTranslation("products-page");
  const { setValue, register, unregister, watch, formState, trigger } =
    useFormContext();
  const products = watch("products", []) as KitProduct[];
  const currentProductId = watch("id");

  useEffect(() => {
    register("products", {
      minLength: { value: 1, message: t("PRODUCT.KIT_PRODUCTS_REQUIRED") },
      required: t("PRODUCT.KIT_PRODUCTS_REQUIRED"),
    });

    return () => unregister("products");
  }, []);

  const loadProductOptions = (
    text: string,
    callback: (options: KitProductOption[]) => void
  ) => {
    searchProductsV4({
      data: {
        text,
        supplierId: user.id,
        countryCode: user.countryCode,
        page: 0,
        size: 15,
        sort: {
          field: "name",
          sort: "asc",
        },
      },
    })
      .then(data =>
        data.content.map(product => ({
          label: product.name,
          value: product.id,
          disabled: product.stock <= 0,
        }))
      )
      .then(callback);
  };

  return (
    <div
      className={clsx(
        styles.kitProducts,
        formState.errors.products && styles.hasError
      )}
    >
      <label htmlFor="kitProducts" className={styles.label}>
        {t("PRODUCT.KIT_PRODUCTS")}
      </label>

      <AsyncSelect
        id="kitProducts"
        cacheOptions
        loadOptions={debounce(loadProductOptions, 300)}
        value={{ label: t("PRODUCT.KIT_SEARCH"), value: "", disabled: false }}
        onChange={selected => {
          if (selected) {
            setValue("products", [
              ...products,
              { id: selected.value, quantity: 1 },
            ]);
            trigger("products");
          }
        }}
        styles={singleSelectStyles}
        placeholder={t("PRODUCT.KIT_SEARCH")}
        noOptionsMessage={() => tGlobal("NO_OPTIONS")}
        isOptionDisabled={option =>
          option.disabled ||
          option.value === currentProductId ||
          products.some(p => p.id === option.value)
        }
      />

      <div className="spacer" />

      {products.length === 0 ? (
        <div className={styles.emptyMessage}>
          {t("PRODUCT.KIT_NO_PRODUCTS")}
        </div>
      ) : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: "100%" }}>{t("PRODUCT.PRODUCT")}</th>
              <th>{t("PRODUCT.STOCK")}</th>
              <th>{t("QUANTITY")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.id} className={styles.row}>
                <td>
                  <ProductConsumer id={product.id}>
                    {({ product }) => <span>{product.name}</span>}
                  </ProductConsumer>
                </td>
                <td>
                  <ProductConsumer id={product.id}>
                    {({ product }) => <span>{product.availableStock}</span>}
                  </ProductConsumer>
                </td>
                <td>
                  <InputV2
                    name={`products.${index}.quantity`}
                    label=""
                    type="number"
                    min={1}
                    required
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() =>
                      setValue(
                        "products",
                        products.filter((_, i) => i !== index)
                      )
                    }
                  >
                    <FaTimes color="red" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="spacer" />

      {formState.errors.products && (
        <span className={styles.errorMessage}>
          {formState.errors.products.message?.toString()}
        </span>
      )}
    </div>
  );
};
