import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect } from "react";
import { ProductsTable } from "@/components/products/ProductsTable/ProductsTable";
import { SearchProductInput } from "@/components/products/ProductsGroupActions/ProductsGroupFilters";
import { useAuth } from "@/store/auth";

const ProductsPage = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("global");
  const { refreshUserData } = useAuth();

  useEffect(() => {
    setTitle(
      t("PRODUCTS.TITLE"),
      "",
      "",
      <>
        <SearchProductInput />
      </>
    );
  }, [t]);

  useEffect(() => {
    refreshUserData();
  }, []);

  return (
    <>
      <ProductsTable />
    </>
  );
};

export default ProductsPage;
