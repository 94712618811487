import { getCurrentCountry, isCO, isUSA } from "@/constants/core";
import {
  TClient,
  TLocation,
  TProduct,
  TPurchase,
  TSellerBillingAddress,
  TSupplierAddress,
} from "@/http";
import { Supplier } from "@/models/supplier";
import { TInvoice } from "./invoiceData";

export const buildLineAddress = (
  location: TLocation | TSellerBillingAddress | undefined
) => {
  let address = location?.addressLine1;
  if (location?.addressLine2) {
    address = `${address}, ${location.addressLine2}`;
  }
  return address;
};

export const buildCountryLine = (
  location: TLocation | TSellerBillingAddress | undefined
) => {
  return location?.state && location.country && location?.city
    ? `${location?.city}, ${location?.state}, ${location?.country}`
    : "";
};

export const billingAddressByClient = (
  client: TClient
): TSellerBillingAddress => {
  return {
    firstName: isUSA ? client.legalName ?? client.firstName : client.firstName,
    sellerId: client.id,
    lastName: client.lastName,
    phonePrefix: client.phonePrefix,
    email: client.email,
    phone: client.phone,
    documentType: client.documentType,
    documentId: client.documentId,
    addressLine1: client?.location?.addressLine1,
    addressLine2: client?.location?.addressLine2,
    city: client?.location?.city,
    state: client?.location?.state,
    country: client?.location?.country,
  };
};
export const buildInlineProperties = (
  properties: { [key: string]: string } | undefined
) => {
  return properties
    ? Object.keys(properties).map(key => ` ${key}: ${properties[key]}`)
    : "";
};

const sanitize = (word: string | undefined) =>
  (word || "").replace(/ /g, "").toLowerCase();

export const buildCleanName = (
  name: string | undefined,
  properties: { [key: string]: string } | undefined,
  customAttributes: { name: string; value: any; visible: boolean }[] = [],
  tax?: number | string
) => {
  return properties
    ? `${name}${isCO || tax ? "\n" + tax + "%\n" : ""} ${Object.keys(properties)
        .filter(key => !sanitize(name).includes(sanitize(properties[key])))
        .map(key => `${key}: ${properties[key]}`)}\n${customAttributes
        .filter(a => a.visible)
        .map(a => `${a.name}: ${a.value}`)
        .join("\n")}`
    : name;
};

export const biuldSupplierInfo = (
  isSupplierSale: boolean,
  supplier: Partial<Supplier>
) => {
  const country = getCurrentCountry();
  if (!isSupplierSale && country == "co") {
    const address: Partial<TSupplierAddress> = {
      addressLine1: "CL 70 BIS 4 41",
      state: "Bogotá",
      city: "Bogotá",
      country: "Colombia",
    };
    const seeriSupplierCo = {
      name: "SEERI COLOMBIA S.A.S",
      addresses: [address],
      logo: "/logo.png",
      companyId: "NIT 901.492.363-7",
    };
    supplier = {
      ...supplier,
      ...seeriSupplierCo,
      phone: undefined,
      phonePrefix: undefined,
      email: undefined,
    };
  } else if (!isSupplierSale && country == "mx") {
    const address: Partial<TSupplierAddress> = {
      addressLine1: "Montes Urales 424",
      state: "México",
      city: "Ciudad de México",
      country: "México",
    };
    const seeriSupplierCo = {
      name: "App Seeri Mexico",
      addresses: [address],
      logo: "/logo.png",
      companyId: "RFC ASM210805LS9",
    };
    supplier = {
      ...supplier,
      ...seeriSupplierCo,
      phone: undefined,
      phonePrefix: undefined,
      email: undefined,
    };
  }
  return supplier;
};

export const biuldInvoiceData = (
  purchase: TPurchase,
  invoiceName: string | undefined,
  showPrices: boolean,
  showClientInfo: boolean,
  supplier: Partial<Supplier>,
  products: TProduct[],
  translate: (...args: any) => any
): TInvoice => {
  return {
    id: purchase.id,
    invoiceName,
    status: purchase.status,
    expiresAt: purchase.expiredAt ? new Date(purchase.expiredAt) : undefined,
    showPrices,
    showClientInfo,
    supplier: supplier,
    client: purchase.client,
    billing:
      purchase.invoiceThirdParty ?? billingAddressByClient(purchase.client),
    number: purchase.purchaseNumber,
    createdAt: new Date(purchase.createdAt),
    subTotal: purchase.subtotal,
    discount: purchase.totalDiscount,
    shipping: purchase.shipping,
    total: purchase.pendingToPay,
    observations: purchase.observations ?? "",
    coins: purchase.partialMethodAmount,
    commercialName: purchase.commercialName ?? "",
    totalTaxes: purchase?.totalTaxes ?? 0,
    items: purchase.products.map(product => {
      const productData = products?.find(p => p.id === product.id);
      return {
        id: product.id,
        sku: product.supplierSku,
        quantity: product.quantity,
        name: product.name,
        brand: product.brand?.name,
        discount: Number(product.totalDiscount) ?? 0,
        total: Number(product.subTotalWithDiscount) ?? 0,
        unitType: product?.unitType,
        subTotal: product.subtotal,
        properties: productData?.productOptions,
        image: productData?.images?.map(i => i.src)?.[0],
        upc: productData?.barCode != "0" ? productData?.barCode : undefined,
        customAttributes: product?.customAttributes ?? [],

        tax:
          isCO || !!product?.taxPercentage
            ? (isUSA ? translate("TAX") : "IVA") +
              ": " +
              product.taxPercentage +
              "%"
            : "",
      };
    }),
    payments: purchase?.payments || [],
  };
};
