import { getCurrentCountry, isCO } from "@/constants/core";
import { useProductsForSale } from "@/hooks/useProducts";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { createPurchase } from "@/http";
import { useAuth } from "@/store/auth";
import { useProducts } from "@/store/products";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeliveryInfoSale } from "./DeliveryInfo/DeliveryInfoSale";
import { SaleProducts } from "./SaleProducts/SaleProducts";
import { SaleResume } from "./SaleResume/SaleResume";
import styles from "./styles.module.scss";
import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { WarehouseInfo } from "./WarehouseInfo/WarehouseInfo";
import { SaleProductsResult } from "./SaleProducts/SaleProductsResult";
import {
  filterSaleUnitRateProducts,
  mapSaleCreationError,
  mapSaleProducts,
} from "@/utils/sale";
import { get } from "lodash-es";
import { isAdmin } from "@/utils/validations/permissions";
import { isProd } from "@/constants/env";
import { Experiment } from "@amplitude/experiment-js-client";

export const SaleCreation = ({
  type = "SALE",
  disabled = false,
}: {
  type?: string;
  disabled?: boolean;
}) => {
  const [t] = useTranslation("purchases");
  const [tGlobal] = useTranslation("global");
  const { selectedProducts, resetSelectedProducts } = useProducts();
  const [search] = useSearchParams("from=orders");
  const [prepurchase, setPrepurchase] = useState<any>({});
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { user, commercial, isCommercial, role } = useAuth();
  const navigate = useNavigate();
  const defaultValues: any = {
    hasStock: "true",
    flagReload: false,
    sellerSearchType: "firstName",
    supplierId: user.id,
    useSameDirection: true,
    purchaseType: type,
    warehouseId: !isAdmin(isCommercial, role)
      ? commercial?.warehouseId ?? ""
      : "",
  };

  const methods = useForm({ defaultValues });
  const origin = search.get("from");
  const { data } = useProductsForSale(origin === "products", selectedProducts);
  const isQuotation = type === "QUOTE";

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      const isSale = values.purchaseType === "SALE";
      const dispatchWarehouse = values?.warehouseId ?? "";
      const productsMapped = mapSaleProducts(
        values?.products ?? [],
        user.allowSerials
      );
      const products = isSale
        ? filterSaleUnitRateProducts(productsMapped, dispatchWarehouse)
        : productsMapped;

      if (!products?.length) {
        showToastError({ message: t("ADD_PRODUCTS") });
        return;
      }

      const payload = {
        ...values,
        products,
        sellerId: values?.seller?.id,
        supplierId: user.id,
        isForSeller: true,
        clientTarget: "own",
        userType: "SELLER",
        countryCode: getCurrentCountry(),
        shipping: values?.shippingPrice,
        voucherUrl: values?.voucherUrl,
        couponCode: values?.coupon,
        type: "SELL_OUT",
      };

      let totalPaid = 0;
      for (let i = 0; i < (values?.payments ?? []).length; i++) {
        totalPaid += Number(get(values.payments[i], "amount", 0));
      }

      payload.paymentStatus =
        totalPaid ===
        Number(prepurchase.total || 0) - Number(values?.coins || 0)
          ? "PAID"
          : "PENDING";
      payload.deliveryStatus = values.isDelivered === true ? "DELIVERED" : null;

      if (isCO) {
        payload.useSameDirection = true;
      }

      if (!isSale) {
        payload.isQuotation = true;
      }

      const purchase = await createPurchase(payload);
      resetSelectedProducts();
      showToastSuccess(t(isSale ? "PURCHASE_CREATED" : "QUOTE_CREATED"));
      navigate(`/${isSale ? "purchases" : "quotes"}/${purchase.id}/show`);
    } catch (error: any) {
      mapSaleCreationError(error, navigate, type, tGlobal);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    methods.setValue("products", data ?? []);
  }, [data]);

  useEffect(() => {
    const apiKey = isProd
      ? import.meta.env.VITE_APP_AMPLITUDE_PROD
      : import.meta.env.VITE_APP_AMPLITUDE_DEV;

    const experiment = Experiment.initialize(apiKey);

    const payload = {
      user_id: user.id,
      device_id: user.id,
      user_properties: {
        custom: "value",
      },
    };
    experiment.fetch(payload).then(() => {
      const flagKey = "search-v4";
      const variant = experiment.variant(flagKey);
      if (variant.value === "on") {
        setIsFeatureAvailable(true);
      } else {
        setIsFeatureAvailable(false);
      }
    });
  }, []);

  return (
    <DisabledZone when={disabled || isLoading}>
      <FormProvider {...methods}>
        <DeliveryInfoSale isQuote={isQuotation} />
        <div className="spacer" />
        <div className="spacer" />
        <SaleProducts useV4={isFeatureAvailable} />
        <div className="spacer" />
        <SaleProductsResult useV4={isFeatureAvailable} />
        <div className="spacer" />
        <div className="flexGap flexWrap flex1 flexSmColumn">
          <WarehouseInfo type={type} prepurchase={prepurchase} />
          <SaleResume
            setPrepurchase={setPrepurchase}
            prepurchase={prepurchase}
            setLoading={setLoading}
          />
        </div>
        {createPortal(
          <div className={styles.footer}>
            <div className="spacerVertical" />
            <button
              className="outlined md"
              onClick={() => navigate(-1)}
              disabled={isLoading}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary md"
              data-testid="createOrderButton"
              onClick={async () => {
                const isValid = await methods.trigger();
                if (isValid) {
                  onSubmit(methods.watch());
                }
              }}
              disabled={isLoading || disabled}
            >
              {isQuotation ? t("CREATE_QUOTE") : t("CREATE")}
            </button>
          </div>,
          document.body
        )}
      </FormProvider>
    </DisabledZone>
  );
};
