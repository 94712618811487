import { DragAndDropFile } from "@/components/shared/DragAndDropFIle/DragAndDropFIle";
import { SelectV2 } from "@/components/shared/Input/SelectV2";
import { Modal } from "@/components/shared/Modal/Modal";
import ToolTip from "@/components/shared/Tooltip/Tooltip";
import { deliveryStatusRendering } from "@/constants/purchases";
import { buckets } from "@/core/buckets";
import { showToastError } from "@/hooks/useToast";
import { updateDeliveryStatusSupplierOrder } from "@/http";
import { useAuth } from "@/store/auth";
import { useOrderDetails } from "@/store/order-detail";
import { uploadFile } from "@/utils/file-upload";
import { nanoid } from "nanoid";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { IoMdRemoveCircle } from "react-icons/io";
import styles from "./styles.module.scss";

export const UpdateDeliveryStatus = () => {
  const [files, setFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getOrderDetail, orderDetail } = useOrderDetails();
  const methods = useForm({ defaultValues: { deliveryStatus: "" } });
  const [t] = useTranslation("orders-page");

  const { user } = useAuth();

  const getNextStatus = (status: string) => {
    let choices: any[] = [];
    switch (status) {
      case "SUPPLIER_PENDING":
      case "PENDING":
        choices = [
          { value: "IN_TRANSIT", label: t("STATUS.IN_TRANSIT") },
          { value: "DELIVERED", label: t("STATUS.DELIVERED") },
        ];
        break;
      case "IN_TRANSIT":
        choices = [
          { value: "DELIVERED", label: t("STATUS.DELIVERED") },
          { value: "PENDING", label: t("STATUS.PENDING") },
        ];
        break;
    }

    return choices;
  };

  const [isOpen, setIsOpen] = useState(false);

  const getColor = (status: string | undefined) => {
    let color = "";
    switch (status) {
      case "supplier_pending":
      case "pending":
      case "rejected":
      case "cancelled":
      case "failed_to_send":
      case "supplier_rejected":
        color = styles.red;
        break;
      case "approved":
      case "delivered":
      case "in_process":
        color = styles.green;
        break;
      case "in_transit":
      case "preparing":
      case "preparation":
      case "delivered_to_supplier":
      case "pickup_from_supplier":
        color = styles.purple;
        break;
    }

    return color;
  };

  const uploadEvidence = async (evidenceFile: any) => {
    const url = await uploadFile(
      buckets.purchases,
      "SupplierEvidence",
      nanoid(),
      evidenceFile
    );

    return url;
  };

  const onSubmit = async ({ deliveryStatus }: any) => {
    try {
      setIsLoading(true);
      let url = "";
      if (files.length) {
        url = await uploadEvidence(files[0]);
      }
      await updateDeliveryStatusSupplierOrder(
        orderDetail.id,
        deliveryStatus,
        url
      );

      getOrderDetail(orderDetail.id, user.id);
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (orderDetail.deliveryProvider !== "SUPPLIER") {
    return null;
  }

  return (
    <>
      <div className={styles.item}>
        <span className={styles.title}>{t("DELIVERY_STATUS")}</span>
        <span
          className={`${styles.description} flexGap ${getColor(
            orderDetail?.deliveryStatus?.toLowerCase()
          )}`}
        >
          <span>
            {
              deliveryStatusRendering.find(
                s => s.code === orderDetail?.deliveryStatus
              )?.name
            }
            {orderDetail?.deliveryStatus === "DELIVERED" &&
              orderDetail.evidenceLink && (
                <>
                  <br />
                  <a
                    href={orderDetail.evidenceLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("SEE_EVIDENCE")}
                  </a>
                </>
              )}
          </span>
          {orderDetail?.deliveryStatus !== "DELIVERED" && (
            <span>
              <ToolTip title={t("UPDATE_DELIVERY_STATUS")}>
                <AiOutlineEdit
                  cursor="pointer"
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}
                />
              </ToolTip>
            </span>
          )}
        </span>
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <h6 className="bolder">{t("UPDATE_DELIVERY_STATUS")}</h6>
        <div className="divider" />
        <FormProvider {...methods}>
          <SelectV2
            label={t("SELECT_DELIVERY_STATUS")}
            name="deliveryStatus"
            choices={getNextStatus(orderDetail.deliveryStatus)}
          />
          {methods.watch("deliveryStatus") === "DELIVERED" && (
            <>
              <p>{t("EVIDENCE")}</p>
              <div>
                {!files.length && (
                  <DragAndDropFile files={files} setFiles={setFiles} />
                )}
                {files.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      marginTop: "0.7rem",
                    }}
                  >
                    <ToolTip title={t("DELETE_EVIDENCE")}>
                      <span className="redText">
                        <IoMdRemoveCircle
                          cursor="pointer"
                          onClick={() => setFiles([])}
                        />
                      </span>
                    </ToolTip>
                    {files[0].name}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="divider" />
          <div className="spacer" />
          <div className="rigthAlign">
            <button
              disabled={isLoading}
              onClick={() => {
                methods.reset({ deliveryStatus: "" });
                setIsOpen(false);
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("UPDATE")}
            </button>
          </div>
        </FormProvider>
      </Modal>
    </>
  );
};
