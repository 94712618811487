import { useTranslation } from "react-i18next";
import { useHeaderTitle } from "@/store/ui";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getAddressListBySellerId,
  getCredits,
  getSellerBillingAddresses,
  getSellerById,
  TSeller,
} from "@/http";
import useSWR from "swr";
import { format } from "date-fns/esm";
import {
  RecordContext,
  useRecordContext,
} from "@/components/clients/ui/RecordContext";
import { AddressTable } from "@/components/clients/AddressTable";
import { TabItem, Tabs } from "@/components/clients/ui/Tab/Tab";
import { AddressModal } from "@/components/clients/AddressModal";
import { Spinner } from "@/components/shared/Spinner/Spinner";
import { BillingAddressTable } from "@/components/clients/BillingAddressTable";
import { CreateBillingAddressModal } from "@/components/purchases/PurchaseCreateForm/PurchaseInvoice/CreateBillingAddressModal";
import { ClientPurchasesTable } from "@/components/clients/ClientPurchasesTable";
import styles from "./styles.module.scss";
import { ClientReportsGrid } from "@/components/clients/Report/ClientReportsGrid";
import { hasPermission } from "@/utils/validations/permissions";
import { useAuth } from "@/store/auth";
import { RecordCustomAttributes } from "@/components/custom-fields/RecordCustomAttributes";
import { ClientCommercial } from "./ClientCommercial";
import { ClientComments } from "./ClientComments";
import { LuExternalLink } from "react-icons/lu";
import { formatCurrency } from "@/utils/currency";
import { ClientCoins } from "./ClientCoins";
import clsx from "clsx";
import { CalendarLink } from "./ClientList";
import { Card } from "@/components/shared/Card/Card";
import { ClientDetail } from "@/components/clients/Events/ClientDetail";
import { ClientEvents } from "@/components/clients/Events/ClientEvents";
import { ClientCategories } from "./ClientCategories";
import { ClientCreditMemo } from "./ClientCreditMemo";
import { isUSA } from "@/constants/core";

const ClientShow = () => {
  const { setTitle } = useHeaderTitle();
  const [t] = useTranslation("clients-page");
  const [tGlobal] = useTranslation("global");
  const { isCommercial, role } = useAuth();
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    setTitle(t("CLIENTS.TITLE"), "/clients", undefined, <CalendarLink />);
  }, [t]);

  const { id } = useParams();

  const { data, error, mutate, isValidating } = useSWR(
    id ? { key: "client", id } : null,
    ({ id }) => getSellerById(id)
  );

  const { data: credits } = useSWR(["credits"], () => {
    const params: any = {};
    if (id) {
      params.search = `sellerId:${id}`;
    }
    return getCredits(params);
  });

  useEffect(() => {
    mutate();
  }, [id]);

  if (error) return <>{t("ERROR")}</>;
  if (!data) return <Spinner />;

  const client = { ...data };
  client.birthDate &&= format(new Date(data.birthDate), "yyyy-MM-dd");

  return (
    <RecordContext.Provider value={client}>
      <div className={clsx(styles.mainContent, "flexGap flexSmColumn")}>
        <div className="col-4 col-sm-12 noPadding">
          <Card className="noPadding" allowOverflow>
            <ClientDetail
              client={client}
              onChange={flag => {
                setReloadData(flag);
                mutate();
              }}
              isValidating={isValidating}
            />
          </Card>
        </div>
        <div className="col-8 col-sm-12 noPadding">
          <Card className="pwmd">
            <Tabs>
              <TabItem label={t("GENERAL")}>
                <div className={styles.tabGeneralGrid}>
                  {(credits?.content?.length ?? 0) > 0 && (
                    <div className="col-12 noPadding">
                      <Link
                        className="bold textUnderline flexGap center"
                        to={`/credits/${credits?.content[0].id}/transactions`}
                      >
                        {t("CREDIT")}{" "}
                        <span className="circleButton">
                          <LuExternalLink />
                        </span>
                      </Link>
                      <p>
                        {t("AMOUNT") +
                          ": " +
                          formatCurrency(credits?.content[0].amount || 0)}
                      </p>
                      <p>
                        {t("BALANCE") +
                          ": " +
                          formatCurrency(credits?.content[0].balance || 0)}
                      </p>
                      <div className="spacer" />
                    </div>
                  )}
                </div>
                {!isValidating && (
                  <>
                    <ClientComments record={client} onDone={mutate} />
                    <div className="spacer" />
                    <RecordCustomAttributes
                      appliesTo="SELLERS"
                      record={client}
                    />

                    <div className="col-12 noPaddingW">
                      <h6 className="bold">{t("COMMERCIAL_RELATED")}</h6>
                      <div className="spacer" />
                      {!isValidating && (
                        <ClientCommercial
                          suppliers={client?.suppliers || []}
                          onDone={mutate}
                          clientId={client.id}
                        />
                      )}
                    </div>

                    <div className="col-12 noPaddingW">
                      <ClientCategories client={client} onDone={mutate} />
                    </div>
                  </>
                )}
              </TabItem>
              {hasPermission(isCommercial, role, [
                "action.clients.show-report",
              ]) && (
                <TabItem label={tGlobal("DASHBOARD.REPORTS")}>
                  <ClientReportsGrid />
                </TabItem>
              )}
              <TabItem label={t("ADRESSES")}>
                <Addresses />
              </TabItem>
              <TabItem label={t("INVOICE_ADDRESSES")}>
                <BillingAddresses />
              </TabItem>
              <TabItem label={t("SHOPPING")}>
                <ClientPurchasesTable />
              </TabItem>
              <TabItem label={tGlobal("COINS")}>
                <ClientCoins />
              </TabItem>
              <TabItem label={"CRM"}>
                <ClientEvents
                  onChange={setReloadData}
                  sharedReload={reloadData}
                />
              </TabItem>

              {isUSA &&
                hasPermission(isCommercial, role, [
                  "action.clients.credit-memo",
                ]) && (
                  <TabItem label={tGlobal("CREDIT_MEMO")}>
                    <ClientCreditMemo />
                  </TabItem>
                )}
            </Tabs>
          </Card>
        </div>
      </div>
    </RecordContext.Provider>
  );
};

export default ClientShow;

const Addresses = () => {
  const [t] = useTranslation("clients-page");
  const [page, setPage] = useState(0);
  const client = useRecordContext<TSeller>();

  const {
    data: list,
    error,
    mutate,
  } = useSWR(
    { key: `client-addresses`, clientId: client.id, page },
    ({ clientId, page }) =>
      getAddressListBySellerId(clientId, { size: 10, page })
  );

  if (error) return <>{t("ERROR")}</>;
  if (!list) return <Spinner />;

  return (
    <>
      <div className={styles.actions}>
        <AddressModal clientId={client.id} onDone={() => mutate()} />
      </div>
      {list.content.length ? (
        <AddressTable
          page={page}
          onPageChange={setPage}
          list={list}
          refresh={mutate}
        />
      ) : (
        <h2 className="bold textCenter">{t("NO_DATA")}</h2>
      )}
    </>
  );
};

const BillingAddresses = () => {
  const [t] = useTranslation("clients-page");
  const [page, setPage] = useState(0);
  const client = useRecordContext<TSeller>();

  const {
    data: list,
    error,
    mutate,
  } = useSWR(
    { key: `client-billing-addresses`, clientId: client.id, page },
    ({ page, clientId }) =>
      getSellerBillingAddresses({
        search: `sellerId:${clientId}`,
        sort: "id,asc",
        size: 10,
        page,
      })
  );

  if (error) return <>{t("ERROR")}</>;
  if (!list) return <Spinner />;

  return (
    <>
      <div className={styles.actions}>
        <CreateBillingAddressModal
          sellerId={client.id}
          onDone={() => mutate()}
        />
      </div>
      {list.content.length ? (
        <BillingAddressTable
          page={page}
          onPageChange={setPage}
          list={list}
          refresh={mutate}
        />
      ) : (
        <h2 className="bold textCenter">{t("NO_DATA")}</h2>
      )}
    </>
  );
};
