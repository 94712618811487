import { PurchaseComments } from "@/components/purchases/PurchaseShow/Comments/PurchaseComments";
import { usePurchaseClient } from "@/hooks/usePurchaseProducts";
import { TOrderDetailsPanel } from "@/models/order-details";
import { usePurchase } from "@/store/purchase";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LastPurchasesUser } from "../LastPurchasesUser/LastPurchasesUser";
import styles from "./styles.module.scss";

export const CommentsSubPurchase = ({ orderDetail }: TOrderDetailsPanel) => {
  const purchaseClient = usePurchaseClient(orderDetail?.purchaseId ?? "");
  const { getPurchase } = usePurchase();
  const [t] = useTranslation("orders-page");

  useEffect(() => {
    if (orderDetail?.purchaseId) {
      getPurchase(orderDetail?.purchaseId);
    }
  }, [orderDetail?.purchaseId]);

  return (
    <>
      <div className="spacer" />
      <div className="flexGap flexSmColumn">
        <div className="col-6 col-sm-12 noPadding mtmd">
          <PurchaseComments />
        </div>
        <div className="col-6 col-sm-12 noPadding">
          <LastPurchasesUser
            useSubpurchases
            clientId={purchaseClient?.id ?? ""}
            customTitle={
              <div className={styles.header}>
                <span className="bold">{t("LAST_ORDERS")}</span>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
