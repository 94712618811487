import { DisabledZone } from "@/components/shared/DisabledZone/DisabledZone";
import { Checkbox } from "@/components/shared/Input/Checkbox";
import { SingleMenu } from "@/components/shared/Menu/SingleMenu";
import { Modal } from "@/components/shared/Modal/Modal";
import { isUSA } from "@/constants/core";
import { DATE_HOUR_NO_SPACES, USA_DATE_HOUR_NO_SPACES } from "@/constants/date";
import { getAccountingIntegration } from "@/hooks/useIntegrations";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import {
  getPurchaseMultipleInvoice,
  multipleInvoiceProcess,
  QUOTED,
} from "@/http";
import { usePurchases } from "@/store/purchases";
import { saveFile } from "@/utils/save-file";
import clsx from "clsx";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";

export const PurchaseProcess = () => {
  const [search] = useSearchParams();
  const [t] = useTranslation("global");
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const currentFilter = statusFilters["status"];
  const isProcurementFilter = search.get("isProcurement") === "true";
  const isQuotes = currentFilter === QUOTED;
  const isSale = !isProcurementFilter && !isQuotes;
  const { selectedPurchases, clearSelectedPurchases } = usePurchases();
  const [action, setAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm();
  const accountingIntegration = getAccountingIntegration();
  const isQuickbooks = accountingIntegration?.supplier == "QUICKBOOKS";

  const validateAction = (value: string) => {
    setAction("");
    setIsOpen(true);
    setAction(value);
  };

  const onClose = () => {
    setAction("");
    setIsOpen(false);
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const purchasesIds = Object.keys(selectedPurchases || {});
      if (action === "MULTIPLE_INVOICE_PURCHASE") {
        await multipleInvoiceProcess(purchasesIds);
        showToastSuccess(t("SHEDULED_INVOICE_MULTIPLE"));
      }
      if (action === "MULTIPLE_REMISSION_PURCHASE") {
        const { hasComments = false, hasTotal = false } = values;
        const blob = await getPurchaseMultipleInvoice({
          hasComments,
          hasTotal,
          ids: purchasesIds,
        });

        await saveFile(
          blob,
          `multiple_orders_remission_${format(
            new Date(),
            isUSA ? USA_DATE_HOUR_NO_SPACES : DATE_HOUR_NO_SPACES
          )}.pdf`
        );
      }
      clearSelectedPurchases();
      onClose();
    } catch (e) {
      showToastError(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isSale) {
    return null;
  }

  return (
    <>
      <DisabledZone when={!Object.keys(selectedPurchases || {}).length}>
        <SingleMenu
          hideOnSelect
          align="rigth"
          label={
            <div
              className={clsx(
                "flexGap center bold contentInline",
                styles.schedule,
                {
                  [styles.disabled]: !Object.keys(selectedPurchases || {})
                    .length,
                }
              )}
            >
              <span>{t("SCHEDULE_PURCHASE")}</span>
              <FaChevronDown size={"0.7rem"} />
            </div>
          }
          options={[
            ...(currentFilter === "IS_INVOICED" && isQuickbooks
              ? [
                  {
                    value: "MULTIPLE_INVOICE_PURCHASE",
                    label: t("MULTIPLE_INVOICE_PURCHASE"),
                  },
                ]
              : []),
            {
              value: "MULTIPLE_REMISSION_PURCHASE",
              label: t("MULTIPLE_REMISSION_PURCHASE"),
            },
          ]}
          onSelect={validateAction}
        />
      </DisabledZone>
      <Modal
        size="sm"
        isOpen={isOpen && !!action}
        onClose={onClose}
        title={t(action)}
        footer={
          <>
            <button onClick={onClose} disabled={isLoading}>
              {t("CANCEL")}
            </button>
            <button
              className="primary lg"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t(
                action === "MULTIPLE_INVOICE_PURCHASE" ? "IM_SURE" : "DOWNLOAD"
              )}
            </button>
          </>
        }
      >
        {action === "MULTIPLE_INVOICE_PURCHASE" && (
          <p>{t("CONFIM_ACTION_MULTIPLE")}</p>
        )}
        {action === "MULTIPLE_REMISSION_PURCHASE" && (
          <>
            <FormProvider {...methods}>
              <div className="spacer" />
              <Checkbox name="hasComments" label={t("INVOICE_COMMENTS")} />
              <Checkbox name="hasTotal" label={t("INVOICE_TOTAL")} />
            </FormProvider>
          </>
        )}
      </Modal>
    </>
  );
};
