import { FilterTabs } from "@/components/app/ButtonFilterTabs/ButtonFilterTabs";
import { quotesRenderFilter } from "@/constants/purchases";
import { PurchaseListTable } from "./PurchaseListTable/PurchaseListTable";
import { PurchaseListFilters } from "./PurchaseListTable/PurchaseListFilters";
import { usePurchaseListFilters } from "@/hooks/usePurchaseProducts";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash-es";
import { usePurchases } from "@/store/purchases";

export const PurchaseList = ({ quotes }: { quotes?: boolean }) => {
  const [t] = useTranslation("orders-page");
  const [search, setSearch] = useSearchParams();
  const statusFilters = JSON.parse(search.get("status") || "{}");
  const filters = usePurchaseListFilters() ?? {};
  const statusFilter = filters["status"] ?? [];
  const { clearSelectedPurchases } = usePurchases();

  const setStatusFilters = (key: string, value: string) => {
    const finalValue = get(statusFilters, key, "") === value ? "" : value;
    const payload = {
      [key]: finalValue,
    };

    const newSearch = new URLSearchParams(search);

    newSearch.set("status", JSON.stringify(payload));
    newSearch.set("page", "0");
    setSearch(newSearch);

    clearSelectedPurchases();
  };

  return (
    <>
      <FilterTabs
        options={
          quotes
            ? quotesRenderFilter
            : [
                ...Object.keys(statusFilter || {})
                  .filter((s: string) => s !== "QUOTED")
                  .map((status: string) => ({
                    text: statusFilter[status],
                    value: status,
                  })),
                { text: "FILTERS.ALL", value: "" },
                { text: t("FILTER.IS_INVOICED"), value: "IS_INVOICED" },
              ]
        }
        currentFilter={get(statusFilters, "status", "")}
        action={(value: string) => setStatusFilters("status", value)}
      />
      <PurchaseListFilters />
      <div className="spacer" />
      <PurchaseListTable quotes={quotes} />
    </>
  );
};
