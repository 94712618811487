export const DEFAULT_FORMAT = "yyyy-MM-dd";
export const DATE_HOUR = "dd-MM-yyyy - HH:mm";
export const DATE_HOUR_SHORT = "dd-MM-yy HH:mm";
export const USA_DATE_HOUR = "MM-dd-yyyy - HH:mm";
export const USA_DATE_HOUR_SHORT = "MM-dd-yy HH:mm";
export const DATE_HOUR_NO_SPACES = "dd_MM_yyyy_HH_mm";
export const USA_DATE_HOUR_NO_SPACES = "MM_dd_yyyy_HH_mm";
export const DATE_MONT_YEAR = "dd-MM-yyyy";
export const USA_DATE_MONT_YEAR = "MM-dd-yyyy";
export const DATE_MONT_YEAR_SLASH = "dd/MM/yyyy";
export const USA_DATE_MONT_YEAR_SLASH = "MM/dd/yyyy";
export const DATE_HOUR_SLASH = "dd/MM/yyyy - HH:mm";
export const USA_DATE_HOUR_SLASH = "MM/dd/yyyy - HH:mm";
export const DATE_TIME = "yyyy-MM-dd'T'HH:mm";
