import { create } from "zustand";

type TUseSubPurchases = {
  selectedSubPurchases: Record<string, any>;
  setSelectedSubPurchases: (product: any) => void;
  clearSelectedSubPurchases: () => void;
};
export const useSubPurchases = create<TUseSubPurchases>((set, get) => ({
  selectedSubPurchases: {},
  clearSelectedSubPurchases: () => {
    set({ selectedSubPurchases: {} });
  },
  setSelectedSubPurchases: (value: any) => {
    const currentSubPurchases = { ...get().selectedSubPurchases };
    if (currentSubPurchases[value.id]) {
      delete currentSubPurchases[value.id];
      set({
        selectedSubPurchases: currentSubPurchases,
      });
    } else {
      set({
        selectedSubPurchases: { ...currentSubPurchases, [value.id]: value },
      });
    }
  },
}));
