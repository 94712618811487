import { TextArea } from "@/components/shared/Input/TextArea";
import { showToastError, showToastSuccess } from "@/hooks/useToast";
import { addPurchaseComment, TSubPurchaseComment } from "@/http";
import { usePurchase } from "@/store/purchase";
import clsx from "clsx";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PurchaseComment } from "./PurchaseComment";
import styles from "./styles.module.scss";

export const PurchaseComments = () => {
  const [t] = useTranslation("global");
  const { getPurchase, purchase } = usePurchase();
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const methods = useForm();

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await addPurchaseComment(purchase.id, values.comment);
      setShowForm(false);
      methods.reset();
      getPurchase(purchase.id);
      showToastSuccess(t("COMMENT_ADDED"));
    } catch (error) {
      showToastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <p className="bold contentInline">
        <span>{t("COMMENTS")}</span>
        {!showForm && (
          <span
            className="pointer purpleText mdFont"
            onClick={() => setShowForm(true)}
          >
            {"+ "}
            {t("ADD_COMMENT")}
          </span>
        )}
      </p>
      {showForm && (
        <>
          <FormProvider {...methods}>
            <TextArea
              name="comment"
              label={t("ADD_COMMENT")}
              rows={3}
              required
            />
          </FormProvider>
          <div className="rigthAlign">
            <button
              className="sm"
              disabled={isLoading}
              onClick={() => {
                setShowForm(false);
                methods.reset();
              }}
            >
              {t("CANCEL")}
            </button>
            <button
              className="primary sm"
              disabled={isLoading}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {t("SAVE")}
            </button>
          </div>
          <div className="spacer" />
        </>
      )}
      {purchase?.comments?.length > 0 ? (
        <>
          <div className={clsx(styles.commentsList, "flexColumn gapSm")}>
            {(purchase?.comments || [])
              ?.sort((a, b) => {
                return (
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
                );
              })
              .map((c: TSubPurchaseComment, i: number) => (
                <PurchaseComment
                  index={i}
                  key={i}
                  comment={c}
                  showAll={showAll}
                  setShowAll={setShowAll}
                />
              ))}
          </div>
        </>
      ) : (
        <p>{t("NO_COMMENTS")}</p>
      )}
    </>
  );
};
