import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
} from "react";
import { useFormContext, Controller } from "react-hook-form";
import { get } from "lodash-es";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type NumericInputProps = {
  name: string;
  label: string | ReactNode;
  placeholder?: string;
  required?: boolean;
  min?: number;
  max?: number | null | undefined;
  step?: number;
  disabled?: boolean;
  decimals?: boolean;
  hideControls?: boolean;
  onChange?: (...params: any[]) => void;
  infoLabel?: string | ReactNode;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
};

export const NumericInput = ({
  name,
  label,
  placeholder,
  required = false,
  min,
  max,
  step = 1,
  disabled,
  decimals = false,
  hideControls = false,
  onChange,
  infoLabel,
  inputProps,
}: NumericInputProps) => {
  const { control, formState, watch, setValue, trigger } = useFormContext();
  const { errors } = formState;
  const [t] = useTranslation("global");

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (decimals) {
      const currentValue = event.currentTarget.value;
      const selectionStart = event.currentTarget.selectionStart ?? 0;
      const regex = /^\d*\.?\d{0,10}$/;

      const newValue =
        currentValue.slice(0, selectionStart) +
        event.key +
        currentValue.slice(selectionStart);

      if (!regex.test(newValue)) {
        event.preventDefault();
      }
      return;
    } else if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "ArrowRight", "ArrowLeft", "Tab"].includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (onChange) {
      onChange(e);
    }

    setValue(name, value);
    trigger(name);
  };

  return (
    <div
      className={`${styles.field} ${get(errors, name) ? styles.required : ""}`}
    >
      <label htmlFor={name}>{label}</label>
      <div className={styles.numericInputContainer}>
        {!hideControls && (
          <button
            className={styles.decrement}
            onClick={e => {
              e.preventDefault();
              if (disabled) {
                return;
              }
              const value = watch(name);
              const numericValue = parseFloat(value) || 0;
              const newValue = (numericValue - step).toString();
              if ((min && numericValue - step >= min) || !min) {
                setValue(name, newValue);
                trigger(name);
              }
            }}
            disabled={disabled}
          >
            -
          </button>
        )}
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? t("FIELD_REQUIRED") : undefined,
            validate: value => {
              if (value !== undefined && value !== null && value !== "") {
                if (typeof value === "number" || /^\d+(\.\d+)?$/.test(value)) {
                  if (typeof max === "number" && value > max) {
                    return `${t("OUT_RANGE_MAX")} ${max}`;
                  }
                  if (typeof min === "number" && value < min) {
                    return `${t("OUT_RANGE_MIN")} ${min}`;
                  }
                  return true;
                }
                return t("NOT_VALID_NUMBER");
              }
              return true;
            },
          }}
          render={({ field }) => (
            <input
              data-testid={name}
              id={name}
              name={name}
              placeholder={placeholder}
              onChange={e => {
                handleInputChange(e);
                field.onChange(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              value={field.value}
              min={min}
              max={max ? max : undefined}
              step={step}
              disabled={disabled}
              autoComplete="off"
              {...inputProps}
              className={clsx(
                {
                  [styles.withoutControllers]: hideControls,
                  [styles.numericInput]: !hideControls,
                },
                inputProps?.className
              )}
            />
          )}
        />
        {!hideControls && (
          <button
            className={styles.increment}
            disabled={disabled}
            onClick={e => {
              e.preventDefault();
              if (disabled) {
                return;
              }
              const value = watch(name);
              const numericValue = parseFloat(value) || 0;
              const newValue = (numericValue + step).toString();
              if ((max && numericValue + step <= max) || !max) {
                setValue(name, newValue);
                trigger(name);
              }
            }}
          >
            +
          </button>
        )}
      </div>
      {!!infoLabel && <span className={styles.infoLabel}>{infoLabel}</span>}
      {!!get(errors, name) && (
        <span className={styles.errorsLabel}>
          {get(errors as any, name).message}
        </span>
      )}
    </div>
  );
};
